import { useCallback, FC, useMemo } from "react";
// hooks
import useUrl from "hooks/useUrl";
import useConsultantServices from "hooks/useConsultantServices";
// components
import Typo from "components/typo";
import CreateBtn from "pages/grant/paper/card/create";
// types
import {
  ConsultantServiceType,
  ConsultantServiceYear,
} from "store/grants/otherDirectCosts/ConsultantServices/types";
// styles
import styles from "pages/grant/paper/card/folders/otherDirectCosts/otherDirectCosts.module.css";
import Item from "pages/grant/paper/card/item";
import { TotalAnchor } from "pages/grant/paper/card/anchor";
import { FullGrant } from "store/grants/types";
import dayjs from "dayjs";

type Props = {
  side: FullGrant;
};

const ConsultantServices: FC<Props> = ({ side }) => {
  const url = useUrl();
  const { isLoading, createService, deleteService } = useConsultantServices();
  const isTotalFolderActive =
    url.subCategory === "consultantServices" && url.categoryId === "totals";

  const handleDeleteService = useCallback(
    (id?: string) => {
      if (id) deleteService(side.id, id, url.categoryId === id);
    },
    [deleteService, url, side]
  );

  const list = useMemo(() => {
    return side.otherDirectCosts.consultantServices.map(
      (service: ConsultantServiceType) => {
        const label = service.name || "Untitled service";
        const links = [
          {
            to: `/grants/${side.id}/otherDC/consultantServices/${service.id}/edit`,
            label: "Service info",
            active:
              url.subCategory === "consultantServices" &&
              url.categoryId === service.id &&
              url.section === "edit",
          },
          ...service.years.map((item: ConsultantServiceYear) => ({
            to: `/grants/${side.id}/otherDC/consultantServices/${service.id}/years/${item.serialNumber}`,
            label: `${dayjs(item.year[0]).format("M/D/YYYY")} - ${dayjs(
              item.year[1]
            ).format("M/D/YYYY")}`,
            active:
              url.subCategory === "consultantServices" &&
              url.categoryId === service.id &&
              url.section === "years" &&
              url.sectionId === `${item.serialNumber}`,
            isDisabled: !service.name,
          })),
        ];
        return (
          <Item
            key={service.id}
            onDelete={() => handleDeleteService(service.id)}
            title={label}
            links={links}
            active={
              url.subCategory === "consultantServices" &&
              url.categoryId === service.id
            }
          />
        );
      }
    );
  }, [side, handleDeleteService, url]);

  return (
    <>
      {list.length > 0 ? (
        <>{list}</>
      ) : (
        <Typo className={styles.placeholder} size={14} type="div">
          No services have been created yet
        </Typo>
      )}

      {list.length > 0 ? (
        <TotalAnchor
          total={side.otherDirectCosts.consultantServicesTotalAmount}
          active={isTotalFolderActive}
          to={`/grants/${side.id}/otherDC/consultantServices/totals`}
        >
          Totals
        </TotalAnchor>
      ) : null}

      <CreateBtn
        loading={isLoading}
        side={side}
        onClick={() => createService(side.id)}
        name="add-consultant-service"
        tip="To add service, you must add grant general information"
      >
        Add service
      </CreateBtn>
    </>
  );
};

export default ConsultantServices;
