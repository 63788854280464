import {
  ChangeEvent,
  Fragment,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { Form, Checkbox, Input, Textarea, Button } from "components/form";
import Icon from "components/icon";
import Typo from "components/typo";
import Modal from "components/modal";
import { numberToMoney } from "helpers/numbers";
import { otherLinkTypes, personalTypesLinks } from "const";
import {
  SectionData,
  TableData,
  TableDataItem,
  TableDataYear,
  TableSection,
} from "hooks/common/mtdcAdapter";
import styles from "./collapseTable.module.css";
import { Link } from "react-router-dom";
import { get, concat, set } from "lodash";
import useUI from "hooks/useUI";
import Tip from "../../components/tip";

type Props = {
  data: TableData;
  rows: TableSection[];
  grantId: string;
  editable?: boolean;
  onSubmit: (data: IUpdateMTDCFlagsInput, cb: () => void) => void;
  onBlur: (data: IUpdateMTDCFlagsInput) => void;
  loading: boolean;
};

export const labels: Record<string, string> = {
  otherDirectCosts: "Other Direct Costs",
  consultantServices: "Consultant Services",
  personnel: "Personnel",
  equipment: "Equipment",
  domestic: "Domestic travels",
  foreign: "Foreign travels",
  postDocPersonnel: "Postdoctoral Research Associates",
  seniorPersonnel: "Senior Personnel",
  academicResearchAssociate: "Academic Research Associates",
  graduateStudents: "Graduate students",
  undergraduateStudents: "Undergraduate Students",
  civilService: "Civil Service",
  unionPersonnel: "Union",
  tempAndCasual: "Temp and Casual",
  otherPersonnel: "Other Personnel",
  undergraduateStudentsAcademic: "Undergraduate Students (Academic)",
  undergraduateStudentsSummer: "Undergraduate Students (Summer)",
  graduateStudentsStipend: "Graduate Students (Stipend)",
  graduateStudentsTuition: "Graduate Students (Tuition)",
  graduateStudentsSummer: "Graduate Students (Summer)",
  graduateStudentsAcademic: "Graduate Students (Academic)",
  materialsAndSupplies: "Materials and Supplies",
  publications: "Publications",
  computerServices: "Computer Services",
  subawards: "Subawards",
  contracts: "Contracts",
  incentivePayments: "Incentive Payments",
  allOtherCosts: "All Other Costs",
};

export type IEditMTDCFlagInputYear = {
  serialNumber: number;
  overriddenIDCValue: number;
};
export type IEditMTDCFlagInput = {
  id?: string;
  isOverriddenIDC: boolean;
  commentIDC: string;
  years: IEditMTDCFlagInputYear[];
};

export interface IUpdateMTDCFlagsInput {
  personnel?: {
    salaries?: {
      seniorPersonnel?: IEditMTDCFlagInput[];
      academicResearchAssociate?: IEditMTDCFlagInput[];
      postDocPersonnel?: IEditMTDCFlagInput[];
      civilService?: IEditMTDCFlagInput[];
      unionPersonnel?: IEditMTDCFlagInput[];
      tempAndCasual?: IEditMTDCFlagInput[];
      otherPersonnel?: IEditMTDCFlagInput[];

      undergraduateStudentsAcademic?: boolean;
      undergraduateStudentsSummer?: boolean;
      graduateStudentsStipend?: boolean;
      graduateStudentsAcademic?: boolean;
      graduateStudentsSummer?: boolean;
    };
    fringeBenefits?: {
      seniorPersonnel?: IEditMTDCFlagInput[];
      academicResearchAssociate?: IEditMTDCFlagInput[];
      postDocPersonnel?: IEditMTDCFlagInput[];
      civilService?: IEditMTDCFlagInput[];
      unionPersonnel?: IEditMTDCFlagInput[];
      tempAndCasual?: IEditMTDCFlagInput[];
      otherPersonnel?: IEditMTDCFlagInput[];

      graduateStudentsStipend?: boolean;
      graduateStudentsTuition?: boolean;
    };
  };
  equipment?: IEditMTDCFlagInput[] | Record<string, IEditMTDCFlagInput[]>;
  participantSupport?:
    | IEditMTDCFlagInput[]
    | Record<string, IEditMTDCFlagInput[]>;
  travel?: {
    types?: {
      domestic: IEditMTDCFlagInput[];
      foreign: IEditMTDCFlagInput[];
    };
  };
  otherDirectCosts?: {
    materialsAndSupplies?: IEditMTDCFlagInput[];
    publications?: IEditMTDCFlagInput[];
    computerServices?: IEditMTDCFlagInput[];
    subawards?: IEditMTDCFlagInput[];
    other?: {
      contracts?: IEditMTDCFlagInput[];
      incentivePayments?: IEditMTDCFlagInput[];
      allOtherCosts?: IEditMTDCFlagInput[];
    };
  };
}

export type CommentedItem = {
  way: string;
  name: string;
  key: string;
  dataItem: TableDataItem | IEditMTDCFlagInput;
};

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

const IndirectCostsTable: FC<Props> = ({
  data,
  editable,
  onBlur,
  onSubmit,
  loading,
  rows,
  grantId,
}) => {
  const [closed, onChangeClosed] = useState<string[]>([]);
  const [opened, onChangeOpened] = useState<string[]>([]);
  const [show, onChangeShow] = useState<boolean>(false);
  const [commentedItem, onChangeCommentedItem] = useState<
    CommentedItem | undefined
  >(undefined);
  const { changeICGrantId } = useUI();

  const allOpened = useMemo(() => {
    const sectionsArray = rows.map((item: TableSection, idx: number) => {
      return Object.values(item.sections).map(
        (section: SectionData, secIdx: number) => `section-${idx}-${secIdx}`
      );
    });
    return concat(...sectionsArray);
  }, [rows]);

  useEffect(() => {
    onChangeOpened(editable ? allOpened : []);
  }, [editable]);

  useEffect(() => {
    changeICGrantId("");
  }, []);

  const [changedMTDC, onChangeChangedMTDC] = useState<IUpdateMTDCFlagsInput>(
    {}
  );
  const special = useMemo(
    () => [
      "undergraduateStudentsAcademic",
      "undergraduateStudentsSummer",
      "graduateStudentsStipend",
      "graduateStudentsAcademic",
      "graduateStudentsSummer",
      "graduateStudentsStipend",
      "graduateStudentsTuition",
    ],
    []
  );

  const submit = useCallback(() => {
    const submitData = {
      ...changedMTDC,
      equipment: concat(...Object.values(changedMTDC.equipment || {})),
      participantSupport: concat(
        ...Object.values(changedMTDC.participantSupport || {})
      ),
    };
    onSubmit(submitData, () => {
      onChangeOpened([]);
    });
  }, [changedMTDC, onSubmit]);

  const preview = useCallback(
    (data: IUpdateMTDCFlagsInput) => {
      const submitData = {
        ...data,
        equipment: concat(...Object.values(data.equipment || {})),
        participantSupport: concat(
          ...Object.values(data.participantSupport || {})
        ),
      };
      onBlur(submitData);
    },
    [onBlur]
  );

  const blurInput = useCallback(() => {
    const submitData = {
      ...changedMTDC,
      equipment: concat(...Object.values(changedMTDC.equipment || {})),
      participantSupport: concat(
        ...Object.values(changedMTDC.participantSupport || {})
      ),
    };
    onBlur(submitData);
  }, [onBlur, changedMTDC]);

  const makeLink = useCallback(
    (item: TableDataItem, section: string) => {
      if (
        ["personnel.salaries", "personnel.fringeBenefits"].includes(section)
      ) {
        const personnelLink = personalTypesLinks[item.key];
        return `/grants/${grantId}/personnel/${personnelLink}/${item.id}/edit`;
      }
      if (["otherDirectCosts", "other"].includes(section)) {
        const odcLink = otherLinkTypes[item.key];
        return `/grants/${grantId}/otherDC/${odcLink}/${item.id}/edit`;
      }
      if (["travel"].includes(section)) {
        return `/grants/${grantId}/travel/grantPersonnel/${item.id}/edit`;
      }
      if (["participantSupport"].includes(section)) {
        return `/grants/${grantId}/events/${item.id}/edit`;
      }
      return `/grants/${grantId}/${section}/${item.id}/edit`;
    },
    [grantId]
  );

  const handleChangeCheckbox = useCallback(
    (
      way: string,
      key: string,
      dataItem: TableDataItem,
      isOverriddenIDC: boolean
    ) => {
      if (special.includes(key)) {
        const existingMTDC: IEditMTDCFlagInput = get(
          changedMTDC,
          [...way.split("."), key],
          {
            isOverriddenIDC,
            commentIDC: "",
            years: dataItem.years.map((year) => ({
              serialNumber: year.serialNumber,
              overriddenIDCValue: year.overriddenIDCValue,
            })),
          }
        );
        const overdriveMTDC = { ...existingMTDC, isOverriddenIDC };
        const newMTDC = set(
          { ...changedMTDC },
          [...way.split("."), key],
          overdriveMTDC
        );
        onChangeChangedMTDC(newMTDC);
        preview(newMTDC);
      } else {
        const existingMTDC: IEditMTDCFlagInput[] = get(
          changedMTDC,
          [...way.split("."), key],
          []
        );
        const overdriveMTDC = existingMTDC
          .map((item) => item.id)
          .includes(dataItem.id)
          ? existingMTDC.map((item) =>
              item.id === dataItem.id ? { ...item, isOverriddenIDC } : item
            )
          : [
              ...existingMTDC,
              {
                id: dataItem.id,
                isOverriddenIDC,
                commentIDC: "",
                years: dataItem.years.map((year) => ({
                  serialNumber: year.serialNumber,
                  overriddenIDCValue: year.overriddenIDCValue,
                })),
              },
            ];
        const newMTDC = set(
          { ...changedMTDC },
          [...way.split("."), key],
          overdriveMTDC
        );
        onChangeChangedMTDC(newMTDC);
        preview(newMTDC);
      }
    },
    [changedMTDC, preview, special]
  );

  const onShowCommentModal = useCallback(
    (
      way: string,
      key: string,
      dataItem: TableDataItem | IEditMTDCFlagInput,
      name: string
    ) => {
      onChangeShow(true);
      onChangeCommentedItem({
        way,
        name,
        key,
        dataItem,
      });
    },
    []
  );

  const handleCloseCommentModal = useCallback(() => {
    onChangeShow(false);
    onChangeCommentedItem(undefined);
  }, []);

  const handleChangeComment = useCallback(
    (commentIDC: string) => {
      if (!commentedItem) return;
      onChangeCommentedItem({
        ...commentedItem,
        dataItem: {
          ...commentedItem.dataItem,
          commentIDC,
        },
      });
    },
    [commentedItem]
  );

  const handleSaveCommentModal = useCallback(() => {
    if (!commentedItem) return;
    const { way, key, dataItem } = commentedItem;
    if (special.includes(key)) {
      const existingMTDC: IEditMTDCFlagInput = get(
        changedMTDC,
        [...way.split("."), key],
        {
          isOverriddenIDC: true,
          commentIDC: dataItem.commentIDC,
          years: dataItem.years.map((year) => ({
            serialNumber: year.serialNumber,
            overriddenIDCValue: year.overriddenIDCValue,
          })),
        }
      );
      const overdriveMTDC = {
        ...existingMTDC,
        commentIDC: dataItem.commentIDC,
      };
      const newMTDC = set(
        { ...changedMTDC },
        [...way.split("."), key],
        overdriveMTDC
      );
      onChangeChangedMTDC(newMTDC);
    } else {
      const existingMTDC: IEditMTDCFlagInput[] = get(
        changedMTDC,
        [...way.split("."), key],
        []
      );
      const overdriveMTDC = existingMTDC
        .map((item) => item.id)
        .includes(dataItem.id)
        ? existingMTDC.map((item) =>
            item.id === dataItem.id
              ? {
                  ...item,
                  commentIDC: dataItem.commentIDC,
                }
              : item
          )
        : [
            ...existingMTDC,
            {
              id: dataItem.id,
              isOverriddenIDC: true,
              commentIDC: dataItem.commentIDC,
              years: dataItem.years.map((year) => ({
                serialNumber: year.serialNumber,
                overriddenIDCValue: year.overriddenIDCValue,
              })),
            },
          ];
      const newMTDC = set(
        { ...changedMTDC },
        [...way.split("."), key],
        overdriveMTDC
      );
      onChangeChangedMTDC(newMTDC);
    }
    onChangeShow(false);
    onChangeCommentedItem(undefined);
  }, [commentedItem, special, changedMTDC]);

  const handleChangeInput = useCallback(
    (
      way: string,
      key: string,
      dataItem: TableDataItem,
      serialNumber: string | number,
      overriddenIDCValue: string | number
    ) => {
      if (special.includes(key)) {
        const existingMTDC: IEditMTDCFlagInput = get(
          changedMTDC,
          [...way.split("."), key],
          {
            isOverriddenIDC: true,
            commentIDC: "",
            years: dataItem.years.map((year) => ({
              serialNumber: year.serialNumber,
              overriddenIDCValue:
                year.serialNumber === serialNumber ? overriddenIDCValue : "",
            })),
          }
        );
        const overdriveMTDC = {
          ...existingMTDC,
          years: existingMTDC.years.map((year) => {
            if (year.serialNumber === serialNumber)
              return { ...year, overriddenIDCValue };
            return year;
          }),
        };
        const newMTDC = set(
          { ...changedMTDC },
          [...way.split("."), key],
          overdriveMTDC
        );
        onChangeChangedMTDC(newMTDC);
      } else {
        const existingMTDC: IEditMTDCFlagInput[] = get(
          changedMTDC,
          [...way.split("."), key],
          []
        );
        const overdriveMTDC = existingMTDC
          .map((item) => item.id)
          .includes(dataItem.id)
          ? existingMTDC.map((item) =>
              item.id === dataItem.id
                ? {
                    ...item,
                    years: item.years.map((year) => {
                      if (year.serialNumber === serialNumber)
                        return { ...year, overriddenIDCValue };
                      return year;
                    }),
                  }
                : item
            )
          : [
              ...existingMTDC,
              {
                id: dataItem.id,
                isOverriddenIDC: true,
                commentIDC: "",
                years: dataItem.years.map((year) => ({
                  serialNumber: year.serialNumber,
                  overriddenIDCValue:
                    year.serialNumber === serialNumber
                      ? overriddenIDCValue
                      : "",
                })),
              },
            ];
        const newMTDC = set(
          { ...changedMTDC },
          [...way.split("."), key],
          overdriveMTDC
        );
        onChangeChangedMTDC(newMTDC);
      }
    },
    [changedMTDC, special]
  );

  const handleClick = useCallback(
    (id: string) => {
      const newClosed = closed.includes(id)
        ? closed.filter((item: string) => item !== id)
        : [...closed, id];
      onChangeClosed(newClosed);
    },
    [closed]
  );

  const setBackToMTDC = useCallback(() => {
    changeICGrantId(grantId);
  }, [grantId, changeICGrantId]);

  const handleClickSubsection = useCallback(
    (id: string) => {
      const newOpened = opened.includes(id)
        ? opened.filter((item: string) => item !== id)
        : [...opened, id];
      onChangeOpened(newOpened);
    },
    [opened]
  );

  const list = useMemo(() => {
    return rows.map((item: TableSection, idx: number) => {
      const sectionsA = Object.values(item.sections);
      return (
        <tbody key={idx}>
          <tr key={`section-${idx}`}>
            <td
              className={cx(styles.icon, styles.first, {
                [styles.closed]: closed.includes(`section-${idx}`),
              })}
            >
              <Icon
                icon={
                  closed.includes(`section-${idx}`)
                    ? "plus-square"
                    : "minus-square"
                }
                onClick={() => handleClick(`section-${idx}`)}
                size={20}
              />
            </td>
            <td className={cx(styles.icon, styles.dummy)}></td>
            <td
              className={cx(styles.black, styles.big)}
              colSpan={data.years.length + 2}
            >
              {`${alphabet[idx]}. ${item.name}`}
            </td>
            {editable ? <td className={styles.chbox} /> : null}
          </tr>
          {sectionsA.map((section: SectionData, secIdx: number) => {
            const isSpecial = special.includes(section.name);
            const specialSectionMTDC: IEditMTDCFlagInput = get(changedMTDC, [
              ...section.section.split("."),
              section.name,
            ]);
            const isSpecialChecked =
              section.totalsItem.isOverriddenIDC ||
              specialSectionMTDC?.isOverriddenIDC;
            return (
              <Fragment key={`section-${idx}-${secIdx}`}>
                <tr
                  className={cx({
                    [styles.hide]: closed.includes(`section-${idx}`),
                  })}
                >
                  <td
                    className={cx(styles.icon, {
                      [styles.last]:
                        secIdx === sectionsA.length - 1 &&
                        closed.includes(`section-${idx}-${secIdx}`),
                    })}
                  />
                  <td
                    className={cx(styles.icon, {
                      [styles.closed]:
                        !opened.includes(`section-${idx}-${secIdx}`) ||
                        section.items.length < 1,
                    })}
                  >
                    {section.items.length > 0 ? (
                      <Icon
                        onClick={() =>
                          handleClickSubsection(`section-${idx}-${secIdx}`)
                        }
                        icon={
                          !opened.includes(`section-${idx}-${secIdx}`)
                            ? "plus-square"
                            : "minus-square"
                        }
                        size={20}
                      />
                    ) : null}
                  </td>
                  <td>{labels[section.name] || section.name}</td>
                  {section.totalsItem.years.map(
                    (year: TableDataYear, yearIdx: number) => (
                      <td className={styles.value} key={yearIdx}>
                        {numberToMoney(year.value)}
                      </td>
                    )
                  )}
                  <td className={styles.value}>
                    {numberToMoney(section.totalsItem.totals)}
                  </td>
                  {editable ? (
                    <td className={styles.chbox}>
                      {isSpecial ? (
                        <Checkbox
                          value={isSpecialChecked}
                          name={`section-${idx}-${secIdx}-checkbox-${isSpecialChecked}`}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleChangeCheckbox(
                              section.section,
                              section.name,
                              section.totalsItem,
                              event.target.checked
                            )
                          }
                        />
                      ) : null}
                    </td>
                  ) : null}
                </tr>
                {editable && isSpecialChecked ? (
                  <tr
                    className={cx({
                      [styles.hide]: closed.includes(`section-${idx}`),
                    })}
                  >
                    <td className={styles.icon} />
                    <td className={styles.icon} />
                    <td className={cx(styles.name, styles.mint)}>
                      {section.totalsItem.name || "N/A"} (overridden){" "}
                      <Icon
                        icon="message-text-square-01"
                        onClick={() =>
                          onShowCommentModal(
                            section.section,
                            section.name,
                            specialSectionMTDC
                              ? specialSectionMTDC
                              : section.totalsItem,
                            section.totalsItem.name
                          )
                        }
                        className={cx(styles.commentIcon, {
                          [styles.active]: Boolean(
                            specialSectionMTDC
                              ? specialSectionMTDC.commentIDC
                              : section.totalsItem.commentIDC
                          ),
                        })}
                      />
                    </td>
                    {section.totalsItem.years.map(
                      (year: TableDataYear, yearIdx: number) => (
                        <td
                          className={cx(
                            styles.value,
                            styles.mint,
                            styles.inputWrapper
                          )}
                          key={yearIdx}
                        >
                          <Input
                            pure
                            inputClassName={styles.input}
                            iconSize={14}
                            name={`section-${idx}-${secIdx}-input-${yearIdx}`}
                            money
                            type="number"
                            onBlur={blurInput}
                            value={
                              specialSectionMTDC
                                ? get(specialSectionMTDC, [
                                    "years",
                                    yearIdx,
                                    "overriddenIDCValue",
                                  ])
                                : year.overriddenIDCValue
                            }
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleChangeInput(
                                section.section,
                                section.name,
                                section.totalsItem,
                                year.serialNumber,
                                event.target.value
                              )
                            }
                          />
                        </td>
                      )
                    )}
                    <td className={cx(styles.value, styles.mint)}>
                      {numberToMoney(section.totalsItem.overriddenTotals)}
                    </td>
                    <td className={cx(styles.mint, styles.chbox)} />
                  </tr>
                ) : null}
                {section.items.map((item: TableDataItem, itIdx: number) => {
                  const sectionMTDC: IEditMTDCFlagInput[] = get(
                    changedMTDC,
                    [...section.section.split("."), item.key],
                    []
                  );
                  const itemInState = sectionMTDC.find(
                    (sectionItem) => sectionItem.id === item.id
                  );
                  const isCheckedInState = sectionMTDC.find(
                    (sectionItem) => sectionItem.id === item.id
                  )?.isOverriddenIDC;
                  return (
                    <Fragment key={`section-${idx}-${secIdx}-${itIdx}`}>
                      <tr
                        className={cx({
                          [styles.hide]:
                            closed.includes(`section-${idx}`) ||
                            !opened.includes(`section-${idx}-${secIdx}`),
                        })}
                      >
                        <td className={styles.icon} />
                        <td className={styles.icon} />
                        <td className={styles.greenName}>
                          <Link
                            to={makeLink(item, section.section)}
                            onClick={setBackToMTDC}
                          >
                            {item.name || "N/A"}
                          </Link>
                        </td>
                        {item.years.map(
                          (year: TableDataYear, yearIdx: number) => (
                            <td
                              className={cx(styles.value, styles.grey)}
                              key={yearIdx}
                            >
                              {numberToMoney(
                                editable ? year.initialIDCValue : year.value
                              )}
                            </td>
                          )
                        )}
                        <td className={cx(styles.value, styles.grey)}>
                          {numberToMoney(
                            editable ? item.initialTotals : item.totals
                          )}
                        </td>
                        {editable ? (
                          <td className={cx(styles.grey, styles.chbox)}>
                            <Tip
                              when={item.isNotCalculatedMTDC}
                              className={styles.tip}
                              left
                              text="Indirect costs cannot be overridden since MTDC has not been calculated."
                            >
                              <Checkbox
                                value={isCheckedInState ?? item.isOverriddenIDC}
                                name={`section-${idx}-${secIdx}-${itIdx}-checkbox-${
                                  item.isOverriddenIDC || isCheckedInState
                                }`}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleChangeCheckbox(
                                    section.section,
                                    item.key,
                                    item,
                                    event.target.checked
                                  )
                                }
                                disabled={item.isNotCalculatedMTDC}
                              />
                            </Tip>
                          </td>
                        ) : null}
                      </tr>
                      {editable &&
                      (isCheckedInState || item.isOverriddenIDC) ? (
                        <tr
                          className={cx({
                            [styles.hide]:
                              closed.includes(`section-${idx}`) ||
                              !opened.includes(`section-${idx}-${secIdx}`),
                          })}
                        >
                          <td className={styles.icon} />
                          <td className={styles.icon} />
                          <td className={cx(styles.name, styles.mint)}>
                            {item.name || "N/A"} (overridden){" "}
                            <Icon
                              icon="message-text-square-01"
                              onClick={() =>
                                onShowCommentModal(
                                  section.section,
                                  item.key,
                                  itemInState ? itemInState : item,
                                  item.name
                                )
                              }
                              className={cx(styles.commentIcon, {
                                [styles.active]: Boolean(
                                  itemInState
                                    ? itemInState.commentIDC
                                    : item.commentIDC
                                ),
                              })}
                            />
                          </td>
                          {item.years.map(
                            (year: TableDataYear, yearIdx: number) => (
                              <td
                                className={cx(
                                  styles.value,
                                  styles.mint,
                                  styles.inputWrapper
                                )}
                                key={yearIdx}
                              >
                                <Input
                                  pure
                                  inputClassName={styles.input}
                                  disabled={item.isNotCalculatedMTDC}
                                  name={`section-${idx}-${secIdx}-${itIdx}-input-${yearIdx}`}
                                  money
                                  type="number"
                                  onBlur={blurInput}
                                  iconSize={14}
                                  value={
                                    itemInState
                                      ? get(itemInState, [
                                          "years",
                                          yearIdx,
                                          "overriddenIDCValue",
                                        ])
                                      : year.overriddenIDCValue
                                  }
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleChangeInput(
                                      section.section,
                                      item.key,
                                      item,
                                      year.serialNumber,
                                      event.target.value
                                    )
                                  }
                                />
                              </td>
                            )
                          )}
                          <td
                            className={cx(
                              styles.value,
                              styles.forbidden,
                              styles.mint
                            )}
                          >
                            {numberToMoney(item.overriddenTotals)}
                          </td>
                          <td className={cx(styles.mint, styles.chbox)} />
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
          {item.totalsItem ? (
            <tr
              key={`section-${idx}-totals`}
              className={cx({
                [styles.hide]: closed.includes(`section-${idx}`),
              })}
            >
              <td className={styles.icon} />
              <td className={styles.icon} />
              <td className={cx(styles.green, styles.big)}>
                {item.totalsItem.name}
              </td>
              {item.totalsItem.years.map(
                (year: TableDataYear, yearIdx: number) => (
                  <td className={cx(styles.value, styles.green)} key={yearIdx}>
                    {numberToMoney(year.value)}
                  </td>
                )
              )}
              <td className={cx(styles.value, styles.green)}>
                {numberToMoney(item.totalsItem.totals)}
              </td>
              {editable ? <td className={styles.chbox} /> : null}
            </tr>
          ) : null}
        </tbody>
      );
    });
  }, [
    rows,
    opened,
    handleChangeInput,
    special,
    blurInput,
    changedMTDC,
    handleChangeCheckbox,
    makeLink,
    setBackToMTDC,
    handleClick,
    closed,
    data,
    handleClickSubsection,
    editable,
  ]);

  return (
    <>
      <Form>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <td colSpan={3}></td>
                {data.years.map((year: TableDataYear, idx: number) => (
                  <td key={idx} className={styles.yearCell}>
                    <span className={styles.yearWrapper}>
                      <span className={styles.yearTitle}>
                        Year {year.serialNumber}
                      </span>
                      <span className={styles.year}>
                        <span>{dayjs(year.year[0]).format("M/D/YYYY")}</span>
                        <span>{dayjs(year.year[1]).format("M/D/YYYY")}</span>
                      </span>
                    </span>
                  </td>
                ))}
                <td>Total</td>
                {editable ? <td>Overridden values</td> : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={cx(styles.icon, styles.dummy)} />
                <td className={cx(styles.icon, styles.dummy)} />
                <td className={cx(styles.green, styles.big)}>
                  {data.totalsItem.name}
                </td>
                {data.totalsItem.years.map(
                  (year: TableDataYear, yearIdx: number) => (
                    <td
                      className={cx(styles.value, styles.green)}
                      key={yearIdx}
                    >
                      {numberToMoney(year.value)}
                    </td>
                  )
                )}
                <td className={cx(styles.value, styles.green)}>
                  {numberToMoney(data.totalsItem.totals)}
                </td>
                {editable ? <td className={styles.chbox} /> : null}
              </tr>
            </tbody>
            {list}
          </table>
        </div>
        <div className={styles.footer}>
          <Button
            htmlType="submit"
            onClick={submit}
            loading={loading}
            name="save-mtdc"
          >
            Save
          </Button>
        </div>
      </Form>
      <Modal
        visible={show}
        title="Leave your comment"
        onCancel={handleCloseCommentModal}
      >
        <Form onSubmit={handleSaveCommentModal}>
          <Typo type="p" className={styles.modalSubtitle}>
            Leave your comment below {commentedItem?.name || "N/A"} (overridden)
          </Typo>
          <Textarea
            label="Comment"
            placeholder="Enter a comment..."
            name="comment"
            value={commentedItem?.dataItem?.commentIDC}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              handleChangeComment(event.target.value)
            }
          />
          <div className={styles.modalFooter}>
            <Button
              name="close-modal"
              size="lg"
              type="bordered"
              htmlType="button"
              onClick={handleCloseCommentModal}
            >
              Cancel
            </Button>
            <Button name="close-modal" size="lg" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default IndirectCostsTable;
