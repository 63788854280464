import { FC } from "react";
import cx from "classnames";
import tick from "./tick.svg";
import styles from "./signUp.module.css";

export type Step = "personal" | "password" | "verify" | "heardof";

type Props = {
  current: Step;
};

type StepItem = {
  step: Step;
  label: string;
};

export const steps: Step[] = ["personal", "password", "verify", "heardof"];

const SignUpWizard: FC<Props> = ({ current }) => {
  const steps: StepItem[] = [
    {
      step: "personal",
      label: "Sign up",
    },
    {
      step: "verify",
      label: "Verify account",
    },
    {
      step: "heardof",
      label: "Feedback",
    },
  ];

  const activeStepId = steps
    .map((item: StepItem) => item.step)
    .findIndex((item: Step) => item === current);

  return (
    <ul className={styles.wizard}>
      {steps.map((item: StepItem, idx: number) => (
        <li
          key={idx}
          className={cx(styles.step, {
            [styles.activeStep]: current === item.step && activeStepId < 2,
            [styles.passedStep]: idx < activeStepId || activeStepId === 2,
          })}
        >
          <span className={styles.stepPoint}>
            {idx < activeStepId || activeStepId === 2 ? (
              <img
                className={styles.icon}
                src={tick}
                alt="tick"
                draggable={false}
              />
            ) : (
              <span className={styles.point} />
            )}
          </span>
          <span className={styles.stepText}>{item.label}</span>
        </li>
      ))}
    </ul>
  );
};

export default SignUpWizard;
