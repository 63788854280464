import Modal from "components/modal";
import styles from "../invitationManageModal/invitationManageModal.module.css";
import Typo from "components/typo";
import { useQuery } from "react-query";
import client from "helpers/apiClient";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { usePagination } from "hooks/common/usePagination";
import { format } from "date-fns";

type HistoryItem = {
  createdBy: string;
  receivedBy?: string;
  createdByUser: string;
  receivedByUser?: string;
  createdAt: string;
  eventType: string;
  eventData: any;
};

const eventTitleMap: Record<string, string> = {
  shareInvitation: "Share Invitation",
  accepted: "Accepted Permissions",
  changeAssignment: "Change Billing",
};

export function InvitationHistoryModal({
  open,
  onOpenChanged,
}: {
  open: boolean;
  onOpenChanged: (a: boolean) => void;
}) {
  return (
    <Modal onCancel={() => onOpenChanged(false)} wide visible={open}>
      <InvitationManageTable />
    </Modal>
  );
}

function InvitationManageTable() {
  const { data: items } = useQuery({
    queryKey: ["invitation-history"],
    queryFn: async () => {
      const response = await client.get("/grant-invitations-history");
      return response.data as HistoryItem[];
    },
  });

  return (
    <div>
      <Typo className={styles.tabTitle} semi>
        View Invitations History
      </Typo>
      {items && <HistoryTable items={items} />}
    </div>
  );
}

function HistoryTable({ items }: { items: HistoryItem[] }) {
  const { pageItems, page, hasNext, hasPrevious, next, previous } =
    usePagination(items);

  return (
    <div>
      <table className={styles.bmTable}>
        <thead>
          <tr>
            <td>Time</td>
            <td>Event Type</td>
            <td>Event</td>
          </tr>
        </thead>
        <tbody>
          {pageItems.map((item, i) => (
            <tr key={i}>
              <td>{format(new Date(item.createdAt), "M/D/YYYY")}</td>
              <td>{eventTitleMap[item.eventType] || "unknown"}</td>
              <td>{formatEventMessage(item)}</td>
            </tr>
          ))}
          {pageItems.length === 0 && (
            <tr>
              <td colSpan={3} style={{ textAlign: "center", padding: "30px" }}>
                No entries found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button
          onClick={previous}
          disabled={!hasPrevious}
          className={styles.paginationButton}
        >
          <ChevronLeft className={styles.paginationButtonIcon} />
          <span>Previous</span>
        </button>
        <span>Page {page}</span>
        <button
          onClick={next}
          disabled={!hasNext}
          className={styles.paginationButton}
        >
          <span>Next</span>
          <ChevronRight className={styles.paginationButtonIcon} />
        </button>
      </div>
    </div>
  );
}

function formatEventMessage(item: HistoryItem) {
  if (item.eventType === "shareInvitation") {
    return `You shared ${
      item.eventData.isInfinitePermission
        ? "unlimited"
        : item.eventData.numberOfPermissions
    } permissions ${
      item.eventData.emails
        ? "to " + item.eventData.emails.split(", ")
        : "via link"
    }`;
  }

  if (item.eventType === "accepted") {
    return `${item.receivedByUser} accepted ${
      item.eventData.isInfinitePermission
        ? "unlimited"
        : item.eventData.numberOfPermissions
    } permissions from ${item.createdByUser}`;
  }

  if (item.eventType === "changeAssignment") {
    return `Grant '${item.eventData.grantTitle}' changed billing from ${
      item.createdByUser || "none"
    } to ${item.receivedByUser || "none"}`;
  }

  return "";
}
