import client from "helpers/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";

import styles from "./AssignOwnerDropdown.module.css";
import { useCallback, useEffect, useState } from "react";
import Avatar from "components/avatar";

import chevronIcon from "./chevron-selector-vertical.png";
import toast from "components/toast";
import cx from "classnames";

function sleep(timeInMs: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timeInMs);
  });
}

type OptionItem = {
  disabled: boolean;
  owner: any;
  includesActiveGrant: boolean;
};

function useOutsideClick(className: string, callback: () => void) {
  const handleClick = useCallback(
    (event: any) => {
      const inElem = event.target?.closest(`.${className}`);
      if (!inElem) {
        callback();
      }
      return;
    },
    [className, callback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [handleClick]);
}

export function AssignOwnerDropdown({ grantId }: { grantId: string }) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { data, refetch } = useQuery({
    queryKey: ["token-assign-options", grantId],
    queryFn: async () => {
      const result = await client.get(
        "/grant-tokens/assign-options/" + grantId
      );
      return result.data as OptionItem[];
    },
  });

  const activeUser = data?.find((r) => r.includesActiveGrant);
  const name =
    activeUser &&
    `${activeUser.owner.firstName || ""} ${
      activeUser.owner.lastName || ""
    }`.trim();

  const assignOwner = useMutation({
    mutationFn: async (selectedOwnerId: string | undefined) => {
      client.patch("/grant-tokens/assign-grant", {
        grantId,
        ownerId: selectedOwnerId,
      });
    },
    onSuccess: async () => {
      setOpen(false);
      await sleep(100);
      await refetch();
      queryClient.invalidateQueries({ queryKey: ["valid-grant", grantId] });
      toast.success("Successfully assigned a new organization to bill");
    },
  });

  useOutsideClick("assign-owner-dropdown", () => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <div style={{ position: "relative" }} className="assign-owner-dropdown">
      <button className={styles.dropdown} onClick={() => setOpen(!open)}>
        {activeUser ? (
          <>
            <Avatar
              alt={name ? name : activeUser.owner.email}
              src={activeUser.owner.profileImage?.url}
              className={styles.icon}
            />
            <span>{activeUser.owner.email}</span>
          </>
        ) : (
          <>
            <span style={{ gridColumn: "span 2 / span 2" }}>
              Assign billing
            </span>
          </>
        )}
        <img src={chevronIcon} />
      </button>
      {open && (
        <ul className={styles.list}>
          {data?.map((item) => (
            <DropdownItem
              key={item.owner.id}
              item={item}
              onClick={() => {
                assignOwner.mutateAsync(item.owner.id);
              }}
            />
          ))}
          {/* {activeUser && (
            <DropdownItem
              key="unassign"
              item={{
                owner: {
                  email: "Please assign billing",
                },
                disabled: false,
                includesActiveGrant: false,
              }}
              hideIcon={true}
              onClick={() => {
                assignOwner.mutateAsync(undefined);
              }}
            />
          )} */}
        </ul>
      )}
    </div>
  );
}

function DropdownItem({
  onClick,
  item,
  hideIcon = false,
}: {
  onClick: () => void;
  item: OptionItem;
  hideIcon?: boolean;
}) {
  const disabled = item.includesActiveGrant || item.disabled;
  const name = `${item.owner.firstName || ""} ${
    item.owner.lastName || ""
  }`.trim();
  return (
    <li
      role="button"
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={cx(styles.listItem)}
    >
      <div className={cx(styles.listItemContent, disabled && styles.disabled)}>
        {hideIcon ? (
          <div className={styles.icon}></div>
        ) : (
          <Avatar
            alt={name ? name : item.owner.email}
            src={item.owner.profileImage?.url}
            className={styles.icon}
          />
        )}
        <div className={styles.link}>{name ? name : item.owner.email}</div>
      </div>
    </li>
  );
}
