import {
  FC,
  useState,
  ChangeEvent,
  useCallback,
  ReactElement,
  useEffect,
} from "react";
import CurrencyInput from "react-currency-input-field";
import cx from "classnames";
import { useFormContext } from "react-hook-form";
import Field from "components/form/field";
import styles from "components/form/form.module.css";
import { findInputError, RHErrors } from "helpers/errors";
import Icon from "components/icon";
import numeral from "numeral";
import { isNumber, isString } from "lodash";

enum MoneyInputFormat {
  InputFormat = "0.00",
  HookFormRegisterFormat = "0,0.00",
  PureMoneyInputFormat = "$0,0.00",
}

type InputType = "text" | "email" | "number" | "password";

export type Props = {
  name: string;
  value?: string | number | null;
  type?: InputType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string | JSX.Element;
  placeholder?: string;
  errors?: string[];
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  max?: number;
  iconSize?: number;
  min?: number;
  pure?: boolean;
  maxLength?: number;
  hint?: string;
  preIcon?: string;
  postIcon?: string;
  minLength?: number;
  inputClassName?: string;
  money?: boolean;
  title?: string;
  help?: ReactElement | string;
  showHelp?: boolean;
  auto?: boolean;
  triangleHelp?: boolean;
  fullError?: boolean;
  onFocus?: (event?: ChangeEvent<HTMLInputElement>) => void;
  step?: string;
  onBlur?: (event?: ChangeEvent<HTMLInputElement>) => void;
  helpDebounce?: number;
  helpPosition?: "left" | "right";
  refProp?: any;
  className?: string;
};

const Input: FC<Props> = (props: Props) => {
  const {
    name,
    value,
    type = "text",
    onChange,
    helpPosition,
    label,
    placeholder,
    className,
    errors = [],
    required: _required,
    readOnly,
    disabled,
    max,
    iconSize,
    min = 0,
    maxLength,
    minLength,
    inputClassName,
    hint,
    preIcon,
    postIcon,
    help,
    helpDebounce,
    showHelp,
    onFocus,
    onBlur,
    auto,
    fullError,
    step,
    triangleHelp,
    title,
    money,
    pure,
  } = props;

  const required = disabled || readOnly ? false : !!_required;
  const [localType, onChangeLocalType] = useState<InputType>(type);
  const { register, setValue, clearErrors, formState } = useFormContext();
  const [focus, onChangeFocus] = useState<boolean>(false);
  const handleChangeLocalType = useCallback(() => {
    if (type !== "password" || disabled) return;
    onChangeLocalType(localType === "password" ? "text" : "password");
  }, [localType, disabled, onChangeLocalType, type]);

  const handleClickKey = useCallback(
    (event: any) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        event.stopPropagation();
        if (onBlur) onBlur();
      }
    },
    [onChange, onBlur]
  );

  useEffect(() => {
    if (auto) {
      setValue(money ? `${name}-money` : name, value);
      if (required && value) clearErrors(money ? `${name}-money` : name);
    }
  }, [value, onChange, auto, name]);

  const defaultPreIcon = money ? "currency-dollar" : preIcon;

  const handleFocusMoneyInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeFocus(true);
      let allowedChars = "01234567890.";
      const value = Array.from(event.target.value)
        .filter((item) => allowedChars.includes(item))
        .join("");
      const modifiedEvent = {
        ...event,
        target: {
          ...event.target,
          value: value,
        },
      };

      if (onFocus) onFocus(modifiedEvent);
    },
    [onFocus]
  );

  const handleBlurMoneyInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeFocus(false);
      let allowedChars = "01234567890.";
      const value = Array.from(event.target.value)
        .filter((item) => allowedChars.includes(item))
        .join("");
      const modifiedEvent = {
        ...event,
        target: {
          ...event.target,
          value: value,
        },
      };

      if (onBlur) onBlur(modifiedEvent);
    },
    [onBlur]
  );

  const handleFocus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onFocus) onFocus(event);
    },
    [onFocus]
  );

  const inputErrors = findInputError(
    formState.errors as RHErrors,
    money ? `${name}-money` : name
  );

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const invalid = errorsList.length > 0;

  const onMoneyInputChange = (value: string | undefined) => {
    let modifiedEvent = {
      target: {
        value: value,
        valueAsNumber: Number(value),
      },
    };
    if (Number(value) < min) {
      modifiedEvent = {
        target: {
          value: `${min}`,
          valueAsNumber: Number(min),
        },
      };
    }

    if (required && value !== "") clearErrors(money ? `${name}-money` : name);
    setValue(name, modifiedEvent.target.value);
    onChange && onChange(modifiedEvent as ChangeEvent<HTMLInputElement>);
  };

  const formattedMoneyValue = useCallback(
    (value: any, format: string) => {
      if (value === "" || value === undefined) return "";
      if (!value && !isNumber(value)) return value;

      if (focus) {
        return value;
      }
      return numeral(value).format(format);
    },
    [money, focus]
  );

  const hookFormRegisterData = money
    ? register(`${name}-money`, {
        value:
          value || typeof value === "number"
            ? formattedMoneyValue(
                value,
                MoneyInputFormat.HookFormRegisterFormat
              )
            : "",
        required: { value: Boolean(required), message: "Field is required" },
        onChange: undefined,
      })
    : register(name, {
        required: { value: Boolean(required), message: "Field is required" },
        pattern:
          type === "email"
            ? {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message:
                  "Please use a valid email format, like: name@example.com",
              }
            : undefined,
        onChange: disabled ? undefined : onChange,
      });

  const numberInput = (
    <CurrencyInput
      decimalsLimit={2}
      decimalScale={2}
      groupSeparator={","}
      className={cx(styles.input, inputClassName, {
        [styles.hasError]: invalid,
        [styles.pureInput]: pure,
      })}
      readOnly={readOnly}
      disabled={disabled}
      title={disabled ? title : undefined}
      step={step ? Number(step) : undefined}
      placeholder={placeholder}
      min={min}
      max={max}
      cypress-id={`${name}-input`}
      minLength={minLength}
      maxLength={maxLength}
      onKeyUp={handleClickKey}
      {...hookFormRegisterData}
      onValueChange={onMoneyInputChange}
      value={
        value || typeof value === "number"
          ? formattedMoneyValue(value, MoneyInputFormat.InputFormat)
          : ""
      }
      onFocus={handleFocusMoneyInput}
      onBlur={handleBlurMoneyInput}
    />
  );

  const input = (
    <input
      className={cx(styles.input, inputClassName, {
        [styles.hasError]: invalid,
        [styles.pureInput]: pure,
      })}
      type={localType}
      readOnly={readOnly}
      title={disabled ? title : undefined}
      disabled={disabled}
      step={step}
      required={required}
      placeholder={placeholder}
      min={type === "number" ? min : undefined}
      max={type === "number" ? max : undefined}
      cypress-id={`${name}-input`}
      minLength={minLength}
      maxLength={maxLength}
      onKeyUp={handleClickKey}
      {...hookFormRegisterData}
      onFocus={handleFocus} //@ts-ignore
      value={value}
      onBlur={onBlur}
    />
  );

  return (
    <Field
      className={cx({ [styles.pureField]: pure }, className)}
      triangleHelp={triangleHelp}
      helpDebounce={helpDebounce}
      showHelp={showHelp}
      help={help}
      pos={helpPosition}
      fullError={fullError}
      required={required}
      label={
        typeof label === "string" && !required
          ? label.replaceAll("*", "")
          : label
      }
      hint={hint}
      errors={errorsList}
      name={money ? `${name}-money` : name}
    >
      <div
        className={cx(styles.inputWrapper, {
          [styles.withPreIcon]: defaultPreIcon,
          [styles.pureWrapper]: pure,
          [styles.withPostIcon]: postIcon || type === "password",
        })}
      >
        {money ? numberInput : input}
        {defaultPreIcon ? (
          <Icon
            size={iconSize || 18}
            className={cx(styles.icon, styles.preicon)}
            icon={defaultPreIcon}
          />
        ) : null}
        {postIcon && type !== "password" ? (
          <Icon
            size={iconSize || 18}
            className={cx(styles.icon, styles.posticon)}
            icon={postIcon}
          />
        ) : null}
        {type === "password" ? (
          <Icon
            onClick={handleChangeLocalType}
            size={18}
            className={cx(styles.icon, styles.passwordIcon, styles.posticon)}
            icon={localType === "text" ? "Icon-11" : "Icon-12"}
          />
        ) : null}
      </div>
    </Field>
  );
};

export default Input;
