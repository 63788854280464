import dayjs from "dayjs";

export const reformatDateMixPanel = (
  value: string | undefined
): string | undefined => {
  if (!value) {
    return value;
  }
  return dayjs(value, "YYYY/MM/DD").format("YYYY-MM-DD");
};

export const stringOrUndefined = (
  value: Date | string | null | undefined
): string | undefined => {
  return value && dayjs(value).isValid()
    ? value
      ? dayjs(value).format("YYYY/MM/DD")
      : value
    : undefined;
};
export const stringOrNull = (
  value: Date | string | null | undefined
): string | null => {
  return value && dayjs(value).isValid()
    ? dayjs(value).format("YYYY/MM/DD")
    : null;
};

export const getYearLabel = (year: string[] = ["", ""], idx: number) => {
  return `Year ${idx + 1} (${dayjs(year[0]).format("M/D/YYYY")} - ${dayjs(
    year[1]
  ).format("M/D/YYYY")})`;
};
