declare global {
  interface Window {
    confetti: any;
  }
}

window.confetti = window.confetti || (() => {});

const defaults = {
  spread: 360,
  ticks: 100,
  gravity: 0,
  decay: 0.9,
  startVelocity: 30,
};

export function shoot() {
  window.confetti({
    ...defaults,
    particleCount: 80,
    scalar: 1.2,
    shapes: ["heart", "star", "heart"],
    colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
  });

  window.confetti({
    ...defaults,
    particleCount: 50,
    scalar: 2,
    shapes: ["emoji"],
    shapeOptions: {
      emoji: {
        value: ["🚀", "🧪", "🔬", "⚗️", "🥼", "🧬", "🧮", "🦄"],
      },
    },
  });

  window.confetti({
    ...defaults,
    particleCount: 5,
    scalar: 2,
    shapes: ["image"],
    shapeOptions: {
      image: [
        {
          src: "https://cdn.brandfetch.io/idZ93xJYZa/w/240/h/240/theme/dark/icon.jpeg?c=1bx1738761187083id4sKw4EiHE884flKG",
          width: 32,
          height: 32,
        },
      ],
    },
  });
}

export function shootMultiple() {
  setTimeout(shoot, 0);
  setTimeout(shoot, 100);
  setTimeout(shoot, 200);
  setTimeout(shoot, 300);
}
