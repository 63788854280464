import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// hooks
import useGrants from "hooks/useGrants";
import usePublications from "hooks/usePublications";
// helpers
import { getPublicationUnitFromGrant } from "helpers/getFromGrant";
// types
import {
  PublicationsUnitYear,
  PublicationsUnit,
} from "store/grants/otherDirectCosts/Publications/types";
// components
import GrantFormWrapper from "pages/grant/common/wrapper";
import Field from "components/field";
import { Input, Checkbox, Form, Button } from "components/form";
// styles
import styles from "../publications.module.css";

type Props = {
  item: PublicationsUnitYear;
  unit: PublicationsUnit;
  grantId: string;
};

const PublicationUnitYearForm: FC<Props> = ({ item, unit, grantId }) => {
  const [data, onChangeData] = useState<PublicationsUnitYear>(item);
  const {
    isLoading,
    updateUnit,
    previewUnitYear,
    trackError,
    trackExit,
    trackFormStarted,
  } = usePublications({ form_page_type: "Date" });

  useEffect(() => {
    trackFormStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNumberOfUnitsBlur = useCallback(() => {
    if (data.isUserCompensation && !data.userCompensationBeforeInflation)
      return;
    if (unit.id && data.numberOfUnits !== "") {
      previewUnitYear(
        grantId,
        unit.id,
        {
          numberOfUnits: data.numberOfUnits,
          serialNumber: data.serialNumber,
          isUserCompensation: Boolean(data.isUserCompensation) ? 1 : 0,
          ...(data.userCompensationBeforeInflation
            ? {
                userCompensationBeforeInflation:
                  data.userCompensationBeforeInflation,
              }
            : {}),
        },
        (response: any) => {
          onChangeData({
            ...response,
            isUserCompensation: response.isUserCompensation,
            calculatedCompensationAfterInflation:
              response.calculatedCompensationAfterInflation,
            calculatedCompensationBeforeInflation:
              response.calculatedCompensationBeforeInflation,
            userCompensationBeforeInflation:
              response.userCompensationBeforeInflation,
            userCompensationAfterInflation:
              response.userCompensationAfterInflation,
            compensationAfterInflation: response.compensationAfterInflation,
            compensationBeforeInflation: response.compensationBeforeInflation,
          });
        }
      );
    }
  }, [previewUnitYear, unit.id, grantId, data]);

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data]
  );

  const handleChangeCheckbox = useCallback(
    (isUserCompensation: boolean) => {
      onChangeData({
        ...data,
        isUserCompensation,
        userCompensationAfterInflation: isUserCompensation
          ? data.userCompensationAfterInflation
          : undefined,
        userCompensationBeforeInflation: isUserCompensation
          ? data.userCompensationBeforeInflation
          : undefined,
      });
    },
    [data]
  );

  const onSubmit = useCallback(() => {
    const newYears = unit.years.map((year: PublicationsUnitYear) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...year,
          isUserCompensation: Boolean(data.isUserCompensation),
          numberOfUnits: Number(data.numberOfUnits),
          compensationBeforeInflation: Number(data.compensationBeforeInflation),
          compensationAfterInflation: Number(data.compensationAfterInflation),
          userCompensationAfterInflation: Number(
            data.userCompensationAfterInflation
          ),
          userCompensationBeforeInflation: Number(
            data.userCompensationBeforeInflation
          ),
        };
      }
      return year;
    });
    updateUnit(grantId, unit.id, {
      ...unit,
      years: newYears,
    });
  }, [data, updateUnit, item, unit, grantId]);

  const hint = `Inflation is not applied in Year ${data.serialNumber} for any costs, but will be applied in subsequent years.`;

  return (
    <Form
      prompt
      onSubmit={onSubmit}
      trackError={trackError}
      trackExit={trackExit}
      trackFormStarted={trackFormStarted}
    >
      <Field full>
        <Input
          name="numberOfUnits"
          label="Number of units per year"
          placeholder="Enter number of units per year"
          className={styles.input}
          min={0}
          onBlur={onNumberOfUnitsBlur}
          value={data.numberOfUnits}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const isZero = Number(event.target.value) === 0;
            handleChangeData({
              numberOfUnits: event.target.value,
              userCompensationBeforeInflation:
                data.isUserCompensation && !isZero
                  ? data.userCompensationBeforeInflation
                  : undefined,
            });
          }}
          type="number"
        />
      </Field>
      <Field full>
        <Input
          name="calculatedCompensationBeforeInflation"
          money
          label={`Total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          disabled
          auto
          value={data.calculatedCompensationBeforeInflation}
          type="number"
        />
      </Field>
      <Field full>
        <Input
          name="calculatedCompensationAfterInflation"
          money
          label={`Total cost after inflation for Year ${data.serialNumber}`}
          help={data.serialNumber === 1 ? hint : undefined}
          placeholder="Total cost after inflation"
          className={styles.input}
          value={data.calculatedCompensationAfterInflation}
          type="number"
          readOnly
          disabled
        />
      </Field>
      <Field full last>
        <Checkbox
          name="isUserCompensation"
          label="I want to adjust the total before inflation"
          value={data.isUserCompensation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeCheckbox(event.target.checked)
          }
        />
      </Field>
      <Field full>
        <Input
          name="userCompensationBeforeInflation"
          label={`Adjusted total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          min={0}
          required={data.isUserCompensation}
          disabled={!data.isUserCompensation}
          money
          onBlur={onNumberOfUnitsBlur}
          value={data.userCompensationBeforeInflation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({
              userCompensationBeforeInflation: event.target.value,
            })
          }
          type="number"
        />
      </Field>
      <Field last full withHelp>
        <Input
          name="userCompensationAfterInflation"
          label={`Adjusted total cost after inflation for Year ${data.serialNumber}`}
          placeholder="Total cost after inflation"
          className={styles.input}
          min={0}
          disabled
          help={data.serialNumber === 1 ? hint : undefined}
          money
          value={data.userCompensationAfterInflation}
          type="number"
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={isLoading} name="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

const PublicationsYearPage: FC = () => {
  const { grant } = useGrants();
  const params: Record<string, string> = useParams();

  const { item, index } = getPublicationUnitFromGrant(grant, params.unitId);
  const year = item?.years[Number(params.yearId)];

  return (
    <GrantFormWrapper
      title="Other Direct Costs"
      subtitle={`Publications. Unit ${index}. Year ${
        Number(params.yearId) + 1
      }`}
    >
      {year ? (
        <PublicationUnitYearForm
          grantId={grant.id}
          unit={item}
          key={`${grant.id}-publications-${params.serviceId}-${params.yearId}-${year?.compensationAfterInflation}`}
          item={year}
        />
      ) : (
        <span />
      )}
    </GrantFormWrapper>
  );
};

export default PublicationsYearPage;
