import { ChangeEvent, useCallback, FC, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import cx from "classnames";
import Field from "components/field";
import styles from "./rate.module.css";
import useEnums from "hooks/useEnums";
import useOrganizations from "hooks/useOrganizations";
import {
  Form,
  Input,
  DatePicker,
  Textarea,
  Select,
  Button,
  Toggler,
  Autocomplete,
} from "components/form";
import Typo from "components/typo";
import Icon from "components/icon";
import { Link } from "react-router-dom";
import useRates from "../../../hooks/useRates";

export type RateData = {
  rate: number | string;
  id?: string;
  startDate: string | Date | null;
  endDate: string | Date | null;
  organizationName: string;
  location: string;
  isGrantExclusive?: boolean;
  comment: string;
  addToUser?: boolean;
  appliedToGrants?: AppliedType[];
};
export type AppliedType = {
  id: string;
  title: string;
  isAccepted: boolean;
};

export type RatePreviewResponse = {
  overlay: {
    startDate: string[];
    endDate: string[];
  };
  uncoveredPeriods: string[][];
};

type Props = {
  timeline: RatePreviewResponse;
  data: RateData;
  update?: boolean;
  grant?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
  onBlur?: () => void;
  accepted?: boolean;
  loading?: boolean;
  onChange: (newData: RateData) => void;
};

const RateForm: FC<Props> = (props: Props) => {
  const {
    data,
    accepted,
    timeline,
    onBlur,
    grant,
    onSubmit,
    update,
    loading,
    onCancel,
    onChange,
  } = props;

  const { trackFormStarted } = useRates({
    form_page_type: "Rates info",
  });

  useEffect(() => {
    trackFormStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fetchApi, fetchOptions, isLoading } = useOrganizations();
  const { FARateLocations } = useEnums();
  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChange({
        ...data,
        ...field,
      });
    },
    [data, onChange]
  );

  const handleBlur = useCallback(() => {
    if (grant && onBlur) {
      onBlur();
      return;
    }
  }, [onBlur, grant]);

  const acceptedRate =
    accepted ||
    (!grant &&
      data.appliedToGrants &&
      data.appliedToGrants.filter((item: AppliedType) => item.isAccepted)
        .length > 0);

  const overlayDisabled =
    grant &&
    (timeline.overlay.startDate.length > 0 ||
      timeline.overlay.endDate.length > 0);

  const timelinePlaceholder = useMemo(() => {
    if (grant) {
      const text =
        timeline.overlay.startDate.length > 0 ||
        timeline.overlay.endDate.length > 0
          ? "Data cannot be saved because some F&A rates cover the same time in the grant"
          : "Such adjustments could lead to uncovered grant period(s):";
      return (
        <div className={styles.placeholder}>
          <div className={styles.title}>
            <Icon
              className={styles.titleIcon}
              size={20}
              icon="alert-triangle"
            />
            <Typo className={styles.titleText}>{text}</Typo>
          </div>
          {timeline.uncoveredPeriods.length > 0 ? (
            <ul className={styles.uncovered}>
              {timeline.uncoveredPeriods.map((dates: string[], idx: number) => (
                <li key={idx}>
                  <Typo className={styles.li}>
                    Rate is missed from {dayjs(dates[0]).format("M/D/YYYY")} to{" "}
                    {dayjs(dates[1]).format("M/D/YYYY")}
                  </Typo>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      );
    }
    return <span />;
  }, [grant, timeline]);

  const acceptedWarning = useMemo(() => {
    return acceptedRate ? (
      <div className={cx(styles.placeholder, styles.head)}>
        <div className={styles.title}>
          <Icon className={styles.titleIcon} size={20} icon="alert-triangle" />
          <Typo className={styles.titleText}>
            Rates applied to won grants with a completion Acceptance form cannot
            be updated or deleted
          </Typo>
        </div>
      </div>
    ) : null;
  }, [acceptedRate]);

  const showTimelinePlaceholder = useMemo(() => {
    if (grant) {
      return (
        Boolean(data.startDate && data.endDate) &&
        (timeline.overlay.endDate.length > 0 ||
          timeline.overlay.startDate.length > 0 ||
          timeline.uncoveredPeriods.length > 0)
      );
    }
    return false;
  }, [grant, data, timeline]);

  const applied = useMemo(() => {
    if (!grant && data.appliedToGrants && data.appliedToGrants?.length > 0) {
      return (
        <div className={styles.appliedWrapper}>
          <Typo className={styles.appliedTitle}>
            {`Applied to grant${data.appliedToGrants.length > 1 ? "s:" : ""}`}
          </Typo>
          <ul>
            {data.appliedToGrants.map((item: AppliedType, idx: number) => (
              <li key={idx}>
                <div className={styles.applied}>
                  <Link to={`/grants/${item.id}/facilities/rates`}>
                    {item.title}
                  </Link>
                  {item.isAccepted ? (
                    <span className={styles.accepted}>
                      <Icon
                        size={12}
                        icon="check-square-broken"
                        className={styles.acceptedIcon}
                      />{" "}
                      Accepted
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }, [grant, data]);

  return (
    <Form onSubmit={onSubmit}>
      {acceptedWarning}
      <Field>
        <Autocomplete
          className={styles.fullInput}
          clearable
          disabled={acceptedRate}
          value={data.organizationName}
          onChange={(value: string | number) =>
            handleChangeData({ organizationName: String(value) })
          }
          onEnter={(value: string | number) =>
            handleChangeData({ organizationName: String(value) })
          }
          name="organizationName"
          options={fetchOptions}
          placeholder="Enter name of organization"
          label="Organization"
          required
          onLoad={fetchApi}
          isLoading={isLoading}
        />
        <Input
          name="rate"
          label="Rate"
          required
          placeholder="Enter rate"
          type="number"
          value={data.rate}
          disabled={acceptedRate}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({ rate: event.target.value })
          }
        />
      </Field>
      <Field
        last={showTimelinePlaceholder}
        className={cx({ [styles.last]: showTimelinePlaceholder })}
      >
        <DatePicker
          name="startDate"
          label="Effective start date"
          required
          value={data.startDate}
          disabled={acceptedRate}
          onBlur={handleBlur}
          onChange={(startDate) => handleChangeData({ startDate })}
        />
        <DatePicker
          name="endDate"
          label="Effective end date"
          required
          minDate={data.startDate ? new Date(data.startDate) : undefined}
          value={data.endDate}
          disabled={acceptedRate}
          onBlur={handleBlur}
          onChange={(endDate) => handleChangeData({ endDate })}
        />
      </Field>
      {showTimelinePlaceholder ? (
        <Field full>{timelinePlaceholder}</Field>
      ) : null}
      <Field>
        <Select
          options={FARateLocations}
          name="location"
          label="Location"
          required
          placeholder="Select the item"
          value={data.location}
          disabled={acceptedRate}
          onChange={(location: any) => handleChangeData({ location })}
        />
      </Field>
      {applied}
      <Field className={styles.textarea} last full>
        <Textarea
          name="comment"
          label="Comment"
          placeholder="Enter a comment..."
          value={data.comment}
          disabled={acceptedRate}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeData({ comment: event.target.value })
          }
        />
      </Field>
      {grant && !update ? (
        <Field full last>
          <Toggler
            label="Add rate to repository"
            name="addToUser"
            value={!data.addToUser}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleChangeData({ addToUser: event.target.checked })
            }
          />
        </Field>
      ) : null}
      <div className={styles.footer}>
        <Button
          htmlType="button"
          onClick={onCancel}
          type="bordered"
          name="cancel-rate"
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          name="submit-rate"
          disabled={acceptedRate || overlayDisabled}
          loading={loading}
        >
          {update ? "Update a rate" : "Add a rate"}
        </Button>
      </div>
    </Form>
  );
};

export default RateForm;
