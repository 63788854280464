import { FC, ReactElement, useEffect } from "react";
import Head from "containers/head";
import Typo from "components/typo";
import useAuth, { AwardedGrant } from "hooks/useAuth";
import { Plan } from "store/auth/types";

import styles from "./billing.module.css";
import { Subscription } from "components/subscription/Subscription";
import SubscriptionSection from "components/builderio/SubscriptionSection";

type Props = {
  plans: Plan[];
  awardedGrant: null | AwardedGrant;
};

const Landing: FC<Props> = ({ plans, awardedGrant }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/4a8288b9-f571-4b0f-bdb6-d743e4fdbac7/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
  (function() {
    function inViewport(element) {
        var m = 100;
        var w = window;
        var r = element.getBoundingClientRect();
        var wB = w.innerHeight;
        var wR = w.innerWidth;
        return ( (0 <= r.top && wB -m >= r.top) || (0 >= r.top && wB <= r.bottom) || (m <= r.bottom && wB >= r.bottom) )
          && ( (0 <= r.left && wR -m >= r.left) || (0 >= r.left && wR <= r.right) || (m <= r.right && wR >= r.right) );
    }
    
    var iframes = [];
    window.addEventListener("message",function(t){
      var e="https://www.paypalobjects.com/api/checkout.4.0.141.js";
      if(!document.querySelector('script[src="'+e+'"]')&&t.data&&"LOAD_PAYPAL"===t.data.action){
      var a=document.createElement("script");a.src=e,a.async=!0,a.dataset={paypalCheckout:!0,noBridge:!0,state:"ppxo_meta",env:"production"},document.body.append(a)
      }if(t.data&&"CALCONIC_UPDATE_HEIGHT"===t.data.action){
      var l=document.querySelectorAll('iframe[src="https://app.calconic.com/api/embed/calculator/'+t.data.payload.id+'"]');
      for (let i in [...l]) {
        l[i].height=t.data.payload.height;
        if (!iframes.includes(l[i])) {
          iframes.push(l[i]);
          let intervalId = setInterval(function() {
            l[i].contentWindow.postMessage({ action: 'IS_ACTIVE' }, '*');
            if (inViewport(l[i])) {
              clearInterval(intervalId);
              l[i].contentWindow.postMessage({ action: 'IN_VIEWPORT' }, '*');
            }
          }, 200);
        }
      };}
    });
  })();
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <Head title="Subscription plan" />
      <div className={styles.header}>
        <Typo type="h4" className={styles.tabTitle} semi>
          <>Subscription plan</>
        </Typo>
        <Typo className={styles.subtitle} size={16}>
          Manage your billing and payment details
        </Typo>
      </div>

      <SubscriptionSection />

      <div className={styles.plans}>
        <Subscription />
      </div>

      <div
        className="senja-embed"
        data-id="4a8288b9-f571-4b0f-bdb6-d743e4fdbac7"
        data-mode="shadow"
        data-lazyload="false"
        style={{ display: "block" }}
      ></div>

      <iframe
        src="https://app.calconic.com/api/embed/calculator/673aabb7023c35002a00727e"
        sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
        title="Calconic_ Calculator"
        name="Calconic_ Calculator"
        height="450px"
        scrolling="no"
        style={{ width: "100%", border: 0, outline: "none" }}
      ></iframe>
    </div>
  );
};

const Billing: FC = () => {
  const { user, paid, managePlan, getAwardedGrant, awardedGrant, plans } =
    useAuth();

  useEffect(() => {
    getAwardedGrant();
  }, []);

  return <Landing plans={plans} awardedGrant={awardedGrant} />;
};

export default Billing;
