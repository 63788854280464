import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import GrantFormWrapper from "pages/grant/common/wrapper";
import Spinner from "components/spinner";
import useUI from "hooks/useUI";
import FinalCollapseTable from "containers/collapseTable/final";
import useGrants from "hooks/useGrants";
import { Button } from "components/form";
import styles from "./final.module.css";
import useRates from "hooks/useRates";
import Icon from "components/icon";
import Typo from "components/typo";
import dayjs from "dayjs";
import useFinalForm from "hooks/useFinalForm";
import Dropdown, { ListItem } from "components/dropdown";
import Modal from "components/modal";
import { useMixPanel } from "../../../../hooks/useMixPanel";
import toast from "components/toast";
import { format } from "date-fns";
import { shootMultiple } from "helpers/confetti";
import { FinalTable } from "components/grid/FinalTable";

const FinalPage: FC = () => {
  const { grantBudgetExported } = useMixPanel();
  const { grant } = useGrants();
  const {
    getGrantRatesPreviewFromStore,
    getGrantRatesFromStore,
    getGrantRates,
  } = useRates();
  const grantRates = getGrantRatesFromStore(grant.id);
  const {
    getTable,
    exportTable,
    downloadLoading,
    exportSummaryTable,
    tableData,
    loading,
  } = useFinalForm();
  const { sidebarWidth, changeICGrantId } = useUI();
  const globalTimeline = getGrantRatesPreviewFromStore(grant.id);
  const params: Record<string, string> = useParams();

  useEffect(() => {
    getTable(params.id);
    getGrantRates(params.id);
  }, [params.id]);

  const rows = useMemo(() => {
    return [
      tableData.sections.personnel,
      tableData.sections.otherPersonnel,
      tableData.sections.fringeBenefits,
      tableData.sections.travel,
      tableData.sections.participantSupport,
      tableData.sections.equipment,
      tableData.sections.otherDirectCosts,
      tableData.sections.directCosts,
      tableData.sections.indirectCost,
      tableData.sections.totalDirectAndIndirectCosts,
      tableData.sections.fees,
      tableData.sections.costSharingExpenses,
    ];
  }, [tableData]);

  const timelinePlaceholder = useMemo(() => {
    const link = `/grants/${grant.id}/facilities/rates`;
    if (grantRates.length < 1) {
      return (
        <div className={styles.warning}>
          <div className={styles.warningTitle}>
            <Icon
              className={styles.warningIcon}
              size={20}
              icon="alert-triangle"
            />
            <Typo className={styles.warningText}>
              <>
                Indirect costs can’t be displayed because F&A rates are absent.
                Follow the
                <Link
                  className={styles.link}
                  onClick={() => changeICGrantId(grant.id)}
                  to={link}
                >
                  link
                </Link>
                to add F&A rates and Indirect costs will be shown.
              </>
            </Typo>
          </div>
        </div>
      );
    }
    if (grant && globalTimeline.uncoveredPeriods.length > 0) {
      const s = globalTimeline.uncoveredPeriods.length > 1 ? "s" : "";
      return (
        <div className={styles.warning}>
          <div className={styles.warningTitle}>
            <Icon
              className={styles.warningIcon}
              size={20}
              icon="alert-triangle"
            />
            <Typo className={styles.warningText}>
              <>
                There are no F&A rate{s} for the period{s} below. Follow the
                <Link
                  className={styles.link}
                  onClick={() => changeICGrantId(grant.id)}
                  to={link}
                >
                  link
                </Link>
                to add F&A rates and Indirect costs will be shown
              </>
            </Typo>
          </div>
          <ul className={styles.uncovered}>
            {globalTimeline.uncoveredPeriods.map(
              (dates: string[], idx: number) => (
                <li key={idx}>
                  <Typo className={styles.li}>
                    Rate is missed from {dayjs(dates[0]).format("M/D/YYYY")} to{" "}
                    {dayjs(dates[1]).format("M/D/YYYY")}
                  </Typo>
                </li>
              )
            )}
          </ul>
        </div>
      );
    }
    return null;
  }, [grant, changeICGrantId, grantRates, globalTimeline]);

  const fileName = `Final form. ${grant.title}`;
  const tableFileName = `${
    grant.primaryInvestigator?.fullName.replaceAll(" ", "_") ||
    "no_primary_investigator"
  }_${grant.title.replaceAll(" ", "_")}_exported_on_${format(
    new Date(),
    "MM-dd-yyyy"
  )}_spreadsheet_version`;
  const nsfFileName = `${
    grant.primaryInvestigator?.fullName.replaceAll(" ", "_") ||
    "no_primary_investigator"
  }_${grant.title.replaceAll(" ", "_")}_exported_on_${format(
    new Date(),
    "MM-dd-yyyy"
  )}_nsf_version`;

  const trackExport = useCallback((export_type: string, file_type: string) => {
    grantBudgetExported(grant.id, export_type, file_type);
  }, []);

  const exportFormMenu: ListItem[] = [
    {
      label: "To Excel file",
      onClick: () => {
        exportTable(grant.id, "xlsx", tableFileName);
        trackExport("Form", "xlsx");
        shootMultiple();
      },
    },
    {
      label: "To CSV file",
      onClick: () => {
        exportTable(grant.id, "csv", tableFileName);
        trackExport("Form", "csv");
        shootMultiple();
      },
    },
  ];

  const exportSummaryMenu: ListItem[] = [
    {
      label: "To Excel file",
      onClick: () => {
        exportSummaryTable(grant.id, "xlsx", nsfFileName);
        trackExport("Summary Proposal Budget", "xlsx");
        shootMultiple();
      },
    },
    {
      label: "To CSV file",
      onClick: () => {
        exportSummaryTable(grant.id, "csv", nsfFileName);
        trackExport("Summary Proposal Budget", "csv");
        shootMultiple();
      },
    },
  ];

  return (
    <GrantFormWrapper className={styles.wrapper} title="Final form">
      <>
        {timelinePlaceholder}
        <div className={styles.header}>
          <Button
            name="copy"
            htmlType="button"
            className={styles.copyBtn}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/share/final-form/${grant.id}`
              );
              toast.info("Copied Link to clipboard");
            }}
          >
            Copy Shareable Link
          </Button>
          <Dropdown
            label="Export Spreadsheet of Budget"
            className={styles.dropdownWrapper}
            hideChevron
            triggerClassName={styles.dropdownTrigger}
            name="export-form-dropdown"
            list={exportFormMenu}
          >
            <Button
              name="export-form"
              type="bordered"
              postIcon="chevron-down"
              htmlType="button"
            >
              Export Spreadsheet of Budget
            </Button>
          </Dropdown>
          <Dropdown
            label="Export Final NSF Form"
            hideChevron
            triggerClassName={styles.dropdownTrigger}
            name="export-summary-dropdown"
            list={exportSummaryMenu}
          >
            <Button
              name="export-summary"
              type="bordered"
              postIcon="chevron-down"
              htmlType="button"
              onClick={() => {}}
            >
              Export Final NSF Form
            </Button>
          </Dropdown>
        </div>
        <FinalTable data={tableData} height={window.innerHeight - 200} />
        <div style={{ paddingTop: 70 }}></div>
        {/* <FinalCollapseTable //@ts-ignore
          rows={rows}
          data={tableData}
          grantId={params.id}
          rates={grantRates}
        /> */}
        {loading ? (
          <Spinner
            style={{
              width: `calc(100vw - ${sidebarWidth}px)`,
              left: sidebarWidth,
            }}
            full
          />
        ) : null}
        <Modal visible={downloadLoading} small>
          <div className={styles.downloadWrapper}>
            <Spinner className={styles.downloadSpinner} />
            <Typo type="h5">Exporting...</Typo>
          </div>
        </Modal>
      </>
    </GrantFormWrapper>
  );
};

export default FinalPage;
