import { FC, ReactElement, useMemo, useState } from "react";
import { Button } from "components/form";
import cx from "classnames";
import styles from "./collapse.module.css";

type Props = {
  title: string;
  text: string | ReactElement;
  className?: string;
  arrow?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const CollapseInfo: FC<Props> = ({
  title,
  arrow,
  text,
  className,
  onOpenChange = () => {},
}) => {
  const [show, onChangeShow] = useState<boolean>(false);

  const icon = useMemo(() => {
    if (arrow) return show ? "chevron-up" : "chevron-down";
    return show ? "minus-circle" : "plus-circle";
  }, [show, arrow]);

  return (
    <div
      className={cx(
        styles.wrapper,
        { [styles.show]: arrow && show },
        className
      )}
    >
      <div
        onClick={() => {
          onChangeShow(!show);
          onOpenChange(!show);
        }}
        className={styles.titleWrapper}
      >
        <span className={styles.title}>{title}</span>
        <Button
          name="collapse"
          size="xxl"
          type="link"
          className={cx(styles.btn, { [styles.arrow]: arrow })}
          postIcon={icon}
        />
      </div>
      {show ? <div className={styles.body}>{text}</div> : null}
    </div>
  );
};

export default CollapseInfo;
