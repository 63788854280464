import { useCallback, FC, useMemo } from "react";
// hooks
import useUrl from "hooks/useUrl";
import dayjs from "dayjs";
import useFees from "hooks/useFees";
// types
import { FeeItemType, FeeItemYearType } from "store/grants/fees/types";
// components
import Typo from "components/typo";
import CreateBtn from "pages/grant/paper/card/create";
import Item from "pages/grant/paper/card/item";
import Anchor, { TotalAnchor } from "pages/grant/paper/card/anchor";
// styles
import styles from "./fees.module.css";
import { FullGrant, SidebarTotals } from "store/grants/types";

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
};

const Fees: FC<Props> = ({ side, totals }) => {
  const url = useUrl();
  const { isLoading, createFee, deleteFee } = useFees();

  const isGeneralInfoFolderActive =
    url.category === "fees" && url.subCategory === "general-info";
  const isTotalFolderActive =
    url.category === "fees" && url.subCategory === "totals";

  const handleDeleteFee = useCallback(
    (id?: string) => {
      if (id) deleteFee(side.id, id, url.subCategory === id);
    },
    [deleteFee, url, side]
  );

  const list = useMemo(() => {
    return side.fees.map((item: FeeItemType) => {
      const links = [
        {
          label: "Fee info",
          to: `/grants/${side.id}/fees/${item.id}/edit`,
          active: url.subCategory === item.id && url.categoryId === "edit",
        },
        ...item.years.map((years: FeeItemYearType, idx: number) => ({
          to: `/grants/${side.id}/fees/${item.id}/year/${idx}`,
          label: `${dayjs(years.year[0]).format("M/D/YYYY")} - ${dayjs(
            years.year[1]
          ).format("M/D/YYYY")}`,
          active: url.subCategory === item.id && url.section === `${idx}`,
          isDisabled:
            !item.name || typeof item.isInflationApplied !== "boolean",
        })),
      ];
      return (
        <Item
          key={item.id}
          onDelete={() => handleDeleteFee(item.id)}
          title={item.name || "Untitled fee"}
          active={url.category === "fees" && url.subCategory === item.id}
          links={links}
        />
      );
    });
  }, [side, handleDeleteFee, url]);

  return (
    <>
      <Anchor
        thin
        to={`/grants/${side.id}/fees/general-info`}
        active={isGeneralInfoFolderActive}
      >
        General info
      </Anchor>

      {list.length > 0 ? (
        list
      ) : (
        <Typo className={styles.placeholder} size={14} type="div">
          No fees have been created yet
        </Typo>
      )}

      {list.length > 0 ? (
        <TotalAnchor
          total={totals.feesTotalAmount}
          to={`/grants/${side.id}/fees/totals`}
          active={isTotalFolderActive}
        >
          Totals
        </TotalAnchor>
      ) : null}

      <CreateBtn
        loading={isLoading}
        side={side}
        tip="To add fee, you must add grant general information"
        onClick={() => createFee(side.id)}
        name="add-grant-participant"
      >
        Add fee
      </CreateBtn>
    </>
  );
};

export default Fees;
