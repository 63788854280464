import {
  FunctionComponent,
  createContext,
  ReactNode,
  useState,
  useCallback,
  MouseEvent,
} from "react";
import { StatGrant } from "store/grants/types";

type ContextMenuType = {
  coords: Coords;
  show: boolean;
  grantId: string | undefined;
  contextGrant: StatGrant | undefined;
  onShowMenu: (event: MouseEvent<any>, grant: StatGrant) => void;
  onHideMenu: () => void;
};

type Coords = {
  x: number;
  y: number;
};

const ContextMenu = createContext<ContextMenuType>({
  show: false,
  grantId: undefined,
  contextGrant: undefined,
  onShowMenu: (event: MouseEvent<any>, grant: StatGrant) => {},
  onHideMenu: () => {},
  coords: {
    x: 0,
    y: 0,
  },
});

type ProviderProps = {
  children: ReactNode | ReactNode[];
};

export const ContextMenuProvider: FunctionComponent<ProviderProps> = ({
  children,
}) => {
  const [show, onChangeShow] = useState<boolean>(false);
  const [contextGrant, onChangeContextGrant] = useState<StatGrant | undefined>(
    undefined
  );
  const [grantId, onChangeGrantId] = useState<string | undefined>(undefined);
  const [coords, onChangeCoords] = useState<Coords>({ x: 0, y: 0 });

  const onShowMenu = useCallback((event: MouseEvent<any>, grant: StatGrant) => {
    event.preventDefault();
    onChangeGrantId(grant.id);
    onChangeContextGrant(grant);
    onChangeShow(true);
    onChangeCoords({
      x: event.clientX,
      y: event.clientY,
    });
  }, []);

  const onHideMenu = useCallback(() => {
    onChangeShow(false);
    onChangeCoords({ x: 0, y: 0 });
    onChangeGrantId(undefined);
    onChangeContextGrant(undefined);
  }, []);

  const value = {
    show,
    onShowMenu,
    onHideMenu,
    contextGrant,
    grantId,
    coords,
  };

  return <ContextMenu.Provider value={value}>{children}</ContextMenu.Provider>;
};

export default ContextMenu;
