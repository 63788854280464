import { FC, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import GrantFormWrapper from "pages/grant/common/wrapper";
import Typo from "components/typo";
import Spinner from "components/spinner";
import useMtdc from "hooks/useMtdc";
import useUI from "hooks/useUI";
import CollapseTable, { IUpdateMTDCFlagsInput } from "containers/collapseTable";
import styles from "./mtdc.module.css";
import useGrants from "hooks/useGrants";
import { MTDCForm } from "./MTDCForm";
import { MdtcTable } from "components/grid/mdtcTable";

const MTDCPage: FC = () => {
  const {
    getMTDC,
    tableData,
    update,
    submitLoading,
    preview,
    loading,
    trackFormStarted,
  } = useMtdc({
    form_page_type: "General info",
  });

  useEffect(() => {
    trackFormStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sidebarWidth } = useUI();
  const { grant } = useGrants();
  const params: Record<string, string> = useParams();

  useEffect(() => {
    getMTDC(params.id);
  }, [params.id]);

  const rows = useMemo(() => {
    return [
      tableData.sections.personnel,
      tableData.sections.otherPersonnel,
      tableData.sections.fringeBenefits,
      tableData.sections.travel,
      tableData.sections.participantSupport,
      tableData.sections.equipment,
      tableData.sections.otherDirectCosts,
    ];
  }, [tableData]);

  const onBlur = useCallback(
    (data: IUpdateMTDCFlagsInput) => {
      preview(grant.id, data);
    },
    [preview, grant]
  );

  const onSubmit = useCallback(
    (data: IUpdateMTDCFlagsInput) => {
      update(grant.id, data);
    },
    [update, grant]
  );

  return (
    <GrantFormWrapper title="Modified Total Direct Costs (MTDC)">
      <>
        <div className={styles.textWrapper}>
          <Typo className={styles.text}>
            Please select which line items will apply for you. For this use the
            check boxes in the last column on the right to indicate which costs
            should be attributed to MTDC. If any costs are not attributed to
            MTDC, the category cost (indicated in black text) will show a zero.
            Default suggestions have been made to assist you. In the majority of
            cases these will be perfectly fine however there will be some cases
            where you have to use unique values.
          </Typo>
          <Typo className={styles.text}>
            Use the buttons in the left two columns to expand and collapse the
            categories in the table.
          </Typo>
          <Typo className={styles.text}>
            If you have a MTDC base rate agreement, you need to exclude the
            following from your MTDC: Participant support, Equipment in excess
            of $5,000, Tuition. May be other exclusions, please refer to your
            rate agreement. A base rate agreement is the same as other
            university agreements. If there isn't an agreement between an
            institution and the government, a default agreement is in place.
            Check with your university budget office.
          </Typo>
          <Typo className={styles.text}>
            Subawards are limited to charging the appropriate F&A rate on the
            first amount, regardless of years or subaward total. Amounts over
            this amount are not considered part of MTDC. Domestic proposing
            organizations that do not have a current negotiated rate agreement
            with a cognizant Federal agency may choose to apply the de minimis
            rate of a certain percentage to a base of modified total direct
            costs (MTDC). Foreign organizations that do not have a current U.S.
            Federally negotiated indirect cost rate(s) are limited to a de
            minimis indirect cost rate recovery of a certain percentage of
            modified total direct costs. Foreign recipients that have a U.S.
            Federally negotiated indirect cost rate(s) may recover indirect
            costs at the current negotiated rate.
          </Typo>
        </div>
        <div>
          <MTDCForm grantId={grant.id} />
        </div>
        <MdtcTable height={window.innerHeight - 200} data={tableData} />
        {/* <CollapseTable //@ts-ignore
          rows={rows}
          data={tableData}
          onBlur={onBlur}
          onSubmit={onSubmit}
          loading={submitLoading}
          grantId={params.id}
        /> */}
        {loading ? (
          <Spinner
            style={{
              width: `calc(100vw - ${sidebarWidth}px)`,
              left: sidebarWidth,
            }}
            full
          />
        ) : null}
      </>
    </GrantFormWrapper>
  );
};

export default MTDCPage;
