import { FC, useCallback, useMemo } from "react";
import { get } from "lodash";
import dayjs from "dayjs";
import Folder from "pages/grant/paper/card/folder";
import PersonnelCreationButton from "./personnelCreationButton";
import Typo from "components/typo";
import useUrl from "hooks/useUrl";
import {
  FullGrant,
  GraduateStudent,
  UnGraduateStudent,
  SeniorPersonal,
  SidebarTotals,
} from "store/grants/types";
import { personalTypes } from "const";
import usePersonnel from "hooks/usePersonnel";
import styles from "./person.module.css";
import Person, { OtherPerson } from "./person";
import Anchor, { TotalAnchor } from "pages/grant/paper/card/anchor";
import Tip from "components/tip";
import PlanButton from "containers/planButton";
import useAuth from "hooks/useAuth";
import { useGrantSubscription } from "hooks/useGrantSubscription";

type Field =
  | "seniorPersonal"
  | "postDocPersonnel"
  | "academicResearchAssociate"
  | "civilService"
  | "unionPersonnel"
  | "tempAndCasual"
  | "otherPersonnel";
type OtherField = "graduateStudents" | "undergraduateStudents";

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
};

type TotalField =
  | "seniorPersonnelTotalAmount"
  | "academicResearchAssociateTotalAmount"
  | "postDocPersonnelTotalAmount"
  | "civilServiceTotalAmount"
  | "unionPersonnelTotalAmount"
  | "tempAndCasualTotalAmount"
  | "otherPersonnelTotalAmount";

const Personnel: FC<Props> = ({ side, totals }) => {
  const {
    deletePerson,
    deleteGraduatedStudents,
    deleteUndergraduatedStudents,
  } = usePersonnel();
  const { categoryId, subCategory, section } = useUrl();
  const paid = useGrantSubscription(side.id);

  const onDelete = useCallback(
    (type: string, person: SeniorPersonal) => {
      deletePerson(side.id, type, person, categoryId === person.id);
    },
    [deletePerson, side, categoryId]
  );

  const onDeleteUndergraduatedStudent = useCallback(() => {
    deleteUndergraduatedStudents(
      side.id,
      side.undergraduateStudents.isAccountOwner,
      subCategory === personalTypes.undergraduateStudents
    );
  }, [deleteUndergraduatedStudents, side, subCategory]);

  const onDeleteGraduatedStudent = useCallback(() => {
    deleteGraduatedStudents(
      side.id,
      side.graduateStudents.isAccountOwner,
      subCategory === personalTypes.graduateStudents
    );
  }, [deleteGraduatedStudents, side, subCategory]);

  const generatePersonWithList = useCallback(
    (
      label: string,
      type: string,
      field: Field,
      text: "person" | "position" = "person",
      totalField: TotalField,
      blocked?: boolean
    ) => {
      const fieldsWithoutNames = [
        "civilService",
        "postDocPersonnel",
        "unionPersonnel",
        "tempAndCasual",
        "otherPersonnel",
      ];
      const PersonComponent = fieldsWithoutNames.includes(field)
        ? OtherPerson
        : Person;
      const placeholder =
        text === "person"
          ? "Personnel not added yet"
          : "Positions not added yet";
      const btn = text === "person" ? "Add person" : "Add position";
      const emptyPlaceholder =
        text === "person" ? "Unnamed person" : "Untitled position";
      //@ts-ignore
      const owners = side[field].filter((person: any) => person.isAccountOwner);
      const hasOwner = owners.length > 0;
      const props = {
        item: {
          label: label,
          type: type,
        },
        hint: hasOwner ? "Marked as Account owner" : undefined,
        customIcon: hasOwner ? "check-verified-02" : undefined,
        children: (
          <div>
            {side[field].map((person: any, idx: number) => (
              <PersonComponent
                key={idx}
                side={side}
                to={`/grants/${side.id}/personnel/${type}/${person.id}/edit`}
                onDelete={() => onDelete(type, person)}
                grantId={side.id}
                type={type}
                active={categoryId === person.id}
                idx={idx + 1}
                person={person}
                emptyPlaceholder={emptyPlaceholder}
              />
            ))}
            {side[field].length > 0 ? (
              <TotalAnchor
                total={totals[totalField] ?? 0}
                to={`/grants/${side.id}/personnel/${type}/totals`}
                active={subCategory === type && categoryId === "totals"}
              >
                Total
              </TotalAnchor>
            ) : (
              <Typo className={styles.placeholder} size={14} type="div">
                {placeholder}
              </Typo>
            )}
            {
              <PersonnelCreationButton
                side={side}
                personnelType={type}
                btnText={btn}
                isDisabled={side.years.length < 1}
                showMissedGeneralInfoTip={side.years.length < 1}
              />
            }
          </div>
        ),
      };
      return (
        <Folder
          hint={props.hint}
          blocked={blocked}
          grantId={side.id}
          customIcon={props.customIcon}
          type="subCategory"
          key={type}
          item={props.item}
        >
          {props.children}
        </Folder>
      );
    },
    [side, totals, onDelete, categoryId, subCategory]
  );

  const generatePersonWithoutList = useCallback(
    (
      label: string,
      type: string,
      field: OtherField,
      compensationField: "annualInfo" | "nsfCompensation",
      onRemove?: () => void
    ) => {
      const nsfList = get(side, [field, compensationField], []);
      const subject: GraduateStudent | UnGraduateStudent = get(side, field);
      const exist = nsfList.length > 0;
      //@ts-ignore
      const hasOwner = side[field].isAccountOwner;
      const links = [
        ...nsfList.map((item: any, idx: number) => ({
          to: `/grants/${side.id}/personnel/${type}/compensation/${idx}`,
          thin: true,
          label: `${dayjs(side.years[idx][0]).format("M/D/YYYY")} - ${dayjs(
            side.years[idx][1]
          ).format("M/D/YYYY")}`,
          active:
            subCategory === type &&
            categoryId === "compensation" &&
            section === `${idx}`,
        })),
        ...(exist
          ? [
              {
                label: "Total",
                isTotal: true,
                to: `/grants/${side.id}/personnel/${type}/totals`,
                active: subCategory === type && categoryId === "totals",
              },
            ]
          : []),
      ];
      const disabled = side.years.length < 1;

      const props = {
        item: {
          label: label,
          type: type,
        },
        hint: hasOwner ? "Marked as Account owner" : undefined,
        customIcon: hasOwner ? "check-verified-02" : undefined,
        children: (
          <div>
            <Tip
              when={disabled}
              top
              text="To add personnel, you must add grant general information"
            >
              <Anchor
                className={styles.link}
                disabled={disabled}
                to={
                  exist
                    ? `/grants/${side.id}/personnel/${type}/edit`
                    : `/grants/${side.id}/personnel/${type}/create`
                }
                active={
                  subCategory === type &&
                  ["edit", "create"].includes(categoryId as string)
                }
                thin
              >
                General info
              </Anchor>
            </Tip>
            {links.map((item, idx) =>
              item.isTotal ? (
                <TotalAnchor
                  active={item.active}
                  total={subject.totalAmount ?? 0}
                  to={item.to}
                  key={idx}
                >
                  {item.label}
                </TotalAnchor>
              ) : (
                <Anchor
                  key={idx}
                  to={item.to}
                  active={item.active}
                  thin={item.thin}
                >
                  {item.label}
                </Anchor>
              )
            )}
          </div>
        ),
      };

      return (
        <Folder
          hint={props.hint}
          blocked={!paid}
          grantId={side.id}
          customIcon={props.customIcon}
          item={props.item}
          onDelete={exist ? onRemove : undefined}
          key={type}
          type="subCategory"
        >
          {props.children}
        </Folder>
      );
    },
    [section, paid, side, categoryId, subCategory]
  );

  const list = useMemo(
    () => [
      generatePersonWithList(
        "Senior Personnel",
        personalTypes.seniorPersonal,
        "seniorPersonal",
        "person",
        "seniorPersonnelTotalAmount"
      ),
      generatePersonWithList(
        "Academic Research Associates",
        personalTypes.academicResearchAssociate,
        "academicResearchAssociate",
        "person",
        "academicResearchAssociateTotalAmount"
      ),
      generatePersonWithList(
        "Postdoctoral Research Associates",
        personalTypes.postDocPersonnel,
        "postDocPersonnel",
        "position",
        "postDocPersonnelTotalAmount"
      ),
      paid ? null : <PlanButton className={styles.plan} />,
      generatePersonWithoutList(
        "Graduate students",
        personalTypes.graduateStudents,
        "graduateStudents",
        "annualInfo",
        onDeleteGraduatedStudent
      ),
      generatePersonWithoutList(
        "Undergraduate Students",
        personalTypes.undergraduateStudents,
        "undergraduateStudents",
        "nsfCompensation",
        onDeleteUndergraduatedStudent
      ),
      generatePersonWithList(
        "Civil Service",
        personalTypes.civil,
        "civilService",
        "position",
        "civilServiceTotalAmount",
        !paid
      ),
      generatePersonWithList(
        "Union",
        personalTypes.union,
        "unionPersonnel",
        "position",
        "unionPersonnelTotalAmount",
        !paid
      ),
      generatePersonWithList(
        "Temp and Casual",
        personalTypes.temp,
        "tempAndCasual",
        "position",
        "tempAndCasualTotalAmount",
        !paid
      ),
      generatePersonWithList(
        "Other Personnel",
        personalTypes.other,
        "otherPersonnel",
        "position",
        "otherPersonnelTotalAmount",
        !paid
      ),
    ],
    [
      paid,
      generatePersonWithList,
      onDeleteUndergraduatedStudent,
      onDeleteGraduatedStudent,
      generatePersonWithoutList,
    ]
  );

  return <>{list}</>;
};

export default Personnel;
