import { Fragment, FC, useCallback, useMemo, useState, useEffect } from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Icon from "components/icon";
import { numberToMoney } from "helpers/numbers";
import { otherLinkTypes, personalTypesLinks } from "const";
import {
  SectionData,
  TableData,
  TableDataItem,
  TableDataYear,
  TableSection,
} from "hooks/common/mtdcAdapter";
import { FinalTableData } from "hooks/common/finalAdapter";
import styles from "./collapseTable.module.css";
import useUI from "hooks/useUI";
import Modal from "components/modal";
import useMtdc from "hooks/useMtdc";
import Typo from "components/typo";
import { Rate } from "store/auth/types";

type Props = {
  data: FinalTableData;
  rows: TableSection[];
  grantId: string;
  rates: Rate[];
};

export const labels: Record<string, string> = {
  otherDirectCosts: "Other Direct Costs",
  fees: "Fees",
  costSharingExpenses: "Cost sharing expenses",
  indirectCost: "Indirect costs",
  directCosts: "Direct costs",
  totalDirectAndIndirectCosts: "Direct and Indirect costs",
  personnel: "Personnel",
  equipment: "Equipment",
  domestic: "Domestic travels",
  foreign: "Foreign travels",
  postDocPersonnel: "Postdoctoral Research Associates",
  seniorPersonnel: "Senior Personnel",
  academicResearchAssociate: "Academic Research Associates",
  graduateStudents: "Graduate students",
  undergraduateStudents: "Undergraduate Students",
  civilService: "Civil Service",
  consultantServices: "Consultant Services",
  unionPersonnel: "Union",
  tempAndCasual: "Temp and Casual",
  otherPersonnel: "Other Personnel",
  undergraduateStudentsAcademic: "Undergraduate Students (Academic)",
  undergraduateStudentsSummer: "Undergraduate Students (Summer)",
  graduateStudentsStipend: "Graduate Students (Stipend)",
  graduateStudentsTuition: "Graduate Students (Tuition)",
  graduateStudentsSummer: "Graduate Students (Summer)",
  graduateStudentsAcademic: "Graduate Students (Academic)",
  materialsAndSupplies: "Materials and Supplies",
  publications: "Publications",
  computerServices: "Computer Services",
  subawards: "Subawards",
  contracts: "Contracts",
  incentivePayments: "Incentive Payments",
  allOtherCosts: "All Other Costs",
};

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

const CollapseFinalTable: FC<Props> = ({ data, rates, rows, grantId }) => {
  const [closed, onChangeClosed] = useState<string[]>([]);
  const [opened, onChangeOpened] = useState<string[]>([]);
  const [show, onChangeShow] = useState<boolean>(false);
  const { changeFinalGrantId } = useUI();
  const { tableData, getMTDC } = useMtdc();
  useEffect(() => {
    changeFinalGrantId("");
    getMTDC(grantId);
  }, [grantId]);

  const handleShowModal = useCallback(() => {
    onChangeShow(true);
  }, []);

  const special = useMemo(
    () => [
      "undergraduateStudentsAcademic",
      "undergraduateStudentsSummer",
      "graduateStudentsStipend",
      "graduateStudentsAcademic",
      "graduateStudentsSummer",
      "graduateStudentsStipend",
      "graduateStudentsTuition",
      "indirectCost",
      "directCosts",
      "totalDirectAndIndirectCosts",
    ],
    []
  );

  const makeLink = useCallback(
    (item: TableDataItem, section: string) => {
      if (
        ["personnel.salaries", "personnel.fringeBenefits"].includes(section)
      ) {
        const personnelLink = personalTypesLinks[item.key];
        return `/grants/${grantId}/personnel/${personnelLink}/${item.id}/edit`;
      }
      if (
        ["otherDirectCosts", "other", "otherDirectCosts.other"].includes(
          section
        )
      ) {
        const odcLink = otherLinkTypes[item.key];
        return `/grants/${grantId}/otherDC/${odcLink}/${item.id}/edit`;
      }
      if (["travel"].includes(section)) {
        return `/grants/${grantId}/travel/grantPersonnel/${item.id}/edit`;
      }
      if (["participantSupport"].includes(section)) {
        return `/grants/${grantId}/events/${item.id}/edit`;
      }
      if (["directCosts"].includes(section)) {
        return `/grants/${grantId}/mtdc`;
      }
      if (["costSharingExpenses"].includes(section)) {
        return `/grants/${grantId}/costSharing/${item.id}/edit`;
      }
      return `/grants/${grantId}/${section}/${item.id}/edit`;
    },
    [grantId]
  );

  const handleClick = useCallback(
    (id: string) => {
      const newClosed = closed.includes(id)
        ? closed.filter((item: string) => item !== id)
        : [...closed, id];
      onChangeClosed(newClosed);
    },
    [closed]
  );

  const setBackToFinal = useCallback(() => {
    changeFinalGrantId(grantId);
  }, [grantId, changeFinalGrantId]);

  const handleClickSubsection = useCallback(
    (id: string) => {
      const newOpened = opened.includes(id)
        ? opened.filter((item: string) => item !== id)
        : [...opened, id];
      onChangeOpened(newOpened);
    },
    [opened]
  );

  const list = useMemo(() => {
    return rows.map((item: TableSection, idx: number) => {
      const sectionsA = Object.values(item.sections || {});
      return (
        <tbody key={idx}>
          <tr key={`section-${idx}`}>
            <td
              className={cx(styles.icon, styles.first, {
                [styles.closed]: closed.includes(`section-${idx}`),
              })}
            >
              <Icon
                icon={
                  closed.includes(`section-${idx}`)
                    ? "plus-square"
                    : "minus-square"
                }
                onClick={() => handleClick(`section-${idx}`)}
                size={20}
              />
            </td>
            <td className={cx(styles.icon, styles.dummy)}></td>
            <td
              className={cx(styles.black, styles.big)}
              colSpan={data.years.length + 2}
            >
              <span className={styles.blackText}>
                {`${alphabet[idx]}. ${item.name}`}
                {item.section === "indirect" ? (
                  <span
                    role="button"
                    onClick={handleShowModal}
                    className={styles.modalBtn}
                  >
                    Calculation of Total Indirect Costs{" "}
                    <Icon
                      icon="arrow-square-up-right"
                      className={styles.modalBtnIcon}
                    />
                  </span>
                ) : null}
              </span>
            </td>
          </tr>
          {sectionsA.map((section: SectionData, secIdx: number) => {
            const disabled =
              !special.includes(section.name) && section.items.length < 1;
            return (
              <Fragment key={`section-${idx}-${secIdx}`}>
                <tr
                  className={cx({
                    [styles.hide]: closed.includes(`section-${idx}`),
                  })}
                >
                  <td
                    className={cx(styles.icon, {
                      [styles.last]:
                        secIdx === sectionsA.length - 1 &&
                        closed.includes(`section-${idx}-${secIdx}`),
                    })}
                  />
                  <td
                    className={cx(styles.icon, {
                      [styles.closed]:
                        !opened.includes(`section-${idx}-${secIdx}`) ||
                        section.items.length < 1,
                    })}
                  >
                    {section.items.length > 0 ? (
                      <Icon
                        onClick={() =>
                          handleClickSubsection(`section-${idx}-${secIdx}`)
                        }
                        icon={
                          !opened.includes(`section-${idx}-${secIdx}`)
                            ? "plus-square"
                            : "minus-square"
                        }
                        size={20}
                      />
                    ) : null}
                  </td>
                  <td
                    className={cx(styles.name, { [styles.disabled]: disabled })}
                  >
                    {labels[section.name] || section.name}
                  </td>
                  {section.totalsItem.years.map(
                    (year: TableDataYear, yearIdx: number) => (
                      <td
                        className={cx(styles.value, {
                          [styles.disabled]: disabled,
                        })}
                        key={yearIdx}
                      >
                        {numberToMoney(year.value)}
                      </td>
                    )
                  )}
                  <td
                    className={cx(styles.value, {
                      [styles.disabled]: disabled,
                    })}
                  >
                    {numberToMoney(section.totalsItem.totals)}
                  </td>
                </tr>
                {section.items.map((item: TableDataItem, itIdx: number) => {
                  return (
                    <Fragment key={`section-${idx}-${secIdx}-${itIdx}`}>
                      <tr
                        className={cx({
                          [styles.hide]:
                            closed.includes(`section-${idx}`) ||
                            !opened.includes(`section-${idx}-${secIdx}`),
                        })}
                      >
                        <td className={styles.icon} />
                        <td className={styles.icon} />
                        <td className={styles.greenName}>
                          <Link
                            to={makeLink(item, section.section)}
                            onClick={setBackToFinal}
                          >
                            {item.name || "N/A"}
                          </Link>
                        </td>
                        {item.years.map(
                          (year: TableDataYear, yearIdx: number) => (
                            <td
                              className={cx(styles.value, styles.grey)}
                              key={yearIdx}
                            >
                              {numberToMoney(year.value)}
                            </td>
                          )
                        )}
                        <td className={cx(styles.value, styles.grey)}>
                          {numberToMoney(item.totals)}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
          {item.totalsItem ? (
            <tr
              key={`section-${idx}-totals`}
              className={cx({
                [styles.hide]: closed.includes(`section-${idx}`),
              })}
            >
              <td className={styles.icon} />
              <td className={styles.icon} />
              <td className={cx(styles.green, styles.big)}>
                {item.totalsItem.name}
              </td>
              {item.totalsItem.years.map(
                (year: TableDataYear, yearIdx: number) => (
                  <td className={cx(styles.value, styles.green)} key={yearIdx}>
                    {numberToMoney(year.value)}
                  </td>
                )
              )}
              <td className={cx(styles.value, styles.green)}>
                {numberToMoney(item.totalsItem.totals)}
              </td>
            </tr>
          ) : null}
        </tbody>
      );
    });
  }, [
    rows,
    opened,
    special,
    makeLink,
    setBackToFinal,
    handleShowModal,
    handleClick,
    closed,
    data,
    handleClickSubsection,
  ]);

  return (
    <>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td colSpan={3}></td>
              {data.years.map((year: string[], idx: number) => (
                <td key={idx} className={styles.yearCell}>
                  <span className={styles.yearWrapper}>
                    <span className={styles.yearTitle}>Year {idx + 1}</span>
                    <span className={styles.year}>
                      <span>{dayjs(year[0]).format("M/D/YYYY")}</span>
                      <span>{dayjs(year[1]).format("M/D/YYYY")}</span>
                    </span>
                  </span>
                </td>
              ))}
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={cx(styles.icon, styles.dummy)} />
              <td className={cx(styles.icon, styles.dummy)} />
              <td className={cx(styles.green, styles.big)}>
                {data.totalsItem.name}
              </td>
              {data.totalsItem.years.map(
                (year: TableDataYear, yearIdx: number) => (
                  <td className={cx(styles.value, styles.green)} key={yearIdx}>
                    {numberToMoney(year.value)}
                  </td>
                )
              )}
              <td className={cx(styles.value, styles.green)}>
                {numberToMoney(data.totalsItem.totals)}
              </td>
            </tr>
          </tbody>
          {list}
        </table>
      </div>
      <Modal
        visible={show}
        fb
        onCancel={() => onChangeShow(false)}
        title="The following data were used to calculate Total Indirect costs"
      >
        <ModalIndirectTables rates={rates} tableData={tableData} />
      </Modal>
    </>
  );
};

export function ModalIndirectTables({
  rates,
  tableData,
}: {
  rates: Rate[];
  tableData: TableData;
}) {
  return (
    <div>
      <div className={cx(styles.tableWrapper, styles.modalTableWrapper)}>
        <Typo className={styles.modalTableTitle}>
          Modified Total Direct Costs (MTDC)
        </Typo>
        <table className={styles.table} id="final-form">
          <thead>
            <tr>
              <td colSpan={3}></td>
              {tableData.years.map((year: TableDataYear, idx: number) => (
                <td key={idx} className={styles.yearCell}>
                  <span className={styles.yearWrapper}>
                    <span className={styles.yearTitle}>
                      Year {year.serialNumber}
                    </span>
                    <span className={styles.year}>
                      <span>{dayjs(year.year[0]).format("M/D/YYYY")}</span>
                      <span>{dayjs(year.year[1]).format("M/D/YYYY")}</span>
                    </span>
                  </span>
                </td>
              ))}
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} className={cx(styles.green, styles.big)}>
                {tableData.totalsItem.name}
              </td>
              {tableData.totalsItem.years.map(
                (year: TableDataYear, yearIdx: number) => (
                  <td className={cx(styles.value, styles.green)} key={yearIdx}>
                    {numberToMoney(year.value)}
                  </td>
                )
              )}
              <td className={cx(styles.value, styles.green)}>
                {numberToMoney(tableData.totalsItem.totals)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={cx(styles.tableWrapper, styles.modalTableWrapper)}>
        <Typo className={styles.modalTableTitle}>
          Facilities and Administrations Rates (F&A Rates)
        </Typo>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>F&A Rate name</td>
              <td>F&A Rate, %</td>
              <td>F&A Rate Start Date</td>
              <td>F&A Rate End Date</td>
              <td>Comment</td>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate: Rate, idx: number) => (
              <tr key={idx}>
                <td>Rate {idx + 1}</td>
                <td className={styles.value}>{`${Number(rate.rate).toFixed(
                  0
                )}%`}</td>
                <td className={styles.value}>
                  {dayjs(rate.startDate).format("M/D/YYYY")}
                </td>
                <td className={styles.value}>
                  {dayjs(rate.endDate).format("M/D/YYYY")}
                </td>
                <td className={styles.value}>{rate.comment || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CollapseFinalTable;
