import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import styles from "./compensation.module.css";
import { Input, Button, Form } from "components/form";
import useGrants from "hooks/useGrants";
import * as T from "hooks/requestTypes";
import usePersonnel from "hooks/usePersonnel";
import { Errors } from "helpers/errors";
import { every } from "lodash";
import { percentToFixed } from "helpers/numbers";
import GrantFormWrapper from "pages/grant/common/wrapper";
import { FieldErrors } from "react-hook-form";

type FormProps = {
  year: number;
  loading: boolean;
  errors: Errors;
  owner: boolean;
  data: any;
  onChange: (field: Record<string, any>) => void;
  onBlur: () => void;
  onSubmit: (event: ChangeEvent<HTMLFormElement>) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
};

const AnnuallyForm: FC<FormProps> = ({
  trackError,
  trackExit,
  trackFormStarted,
  onSubmit,
  loading,
  year,
  owner,
  data,
  onBlur,
  onChange,
  errors,
}) => (
  <Form
    prompt
    onSubmit={onSubmit}
    trackError={trackError}
    trackExit={trackExit}
    trackFormStarted={trackFormStarted}
  >
    <div className={cx(styles.field, styles.firstField)}>
      <Input
        name="numberToReceiveStipends"
        errors={errors.numberToReceiveStipends}
        onBlur={onBlur}
        type="number"
        value={data.numberToReceiveStipends}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ numberToReceiveStipends: event.target.value })
        }
        className={styles.fullInput}
        help="If a graduate student will only be supported for part of a year, include them as a proportion of a graduate student. E.g., if a graduate student will only be supported for 6 months of Year 1, they would count as .5 of a graduate student"
        label={`Number of Graduate Students to receive stipends for Year ${year}`}
        required
        placeholder="Enter number of graduate students to receive stipends"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="totalStipendBeforeInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalStipendBeforeInflation}
        label={`Total Stipend for all graduate students that are supported annually in Year ${year} before inflation`}
        placeholder="Total stipend before inflation"
      />
    </div>
    <div className={styles.lastField}>
      <Input
        name="totalStipendAfterInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalStipendAfterInflation}
        label={`Total Stipend for all graduate students that are supported annually in Year ${year} after inflation`}
        placeholder="Total stipend after inflation"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="numberToReceiveTuitionCoverage"
        help="This does not have to be a whole number. For example, if one graduate student will receive tuition support for only half of the whole academic year, count them as 0.5 of a graduate student. A second example is that if you are on the trimester system and a graduate student will only receive tuition support for one trimester in the academic year for this year, they would count as 0.33 of a graduate student. Not all of the graduate students who are getting stipends need to get tuition support or vice versa"
        errors={errors.numberToReceiveTuitionCoverage}
        onBlur={onBlur}
        value={data.numberToReceiveTuitionCoverage}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ numberToReceiveTuitionCoverage: event.target.value })
        }
        className={styles.fullInput}
        type="number"
        label={`Number of Graduate Students to Receive full Tuition coverage for the whole Academic Year`}
        required
        placeholder="Enter number of graduate students to receive tuition"
      />
    </div>
    <div className={styles.justField}>
      <Input
        name="totalTuitionBeforeInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalTuitionBeforeInflation}
        label={`Total Amount Requested for Tuition for All Graduate Students in Year ${year} before Inflation`}
        placeholder="Total tuition before inflation"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="totalTuitionAfterInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalTuitionAfterInflation}
        label={`Total Amount Requested for Tuition for All Graduate Students in Year ${year} after Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total tuition after inflation"
      />
    </div>
    <div className={styles.lastField}>
      <Input
        name="totalAmountForStipendAndTuition"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalAmountForStipendAndTuition}
        label={`Total Amount Requested For All Graduate Student Stipends and Tuition in Year after Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total tuition after inflation"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="fringeBenefitBasedOnStipend"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.fringeBenefitBasedOnStipend}
        label={`Fringe Benefits For Graduate Students Based on Total Stipend Support in Year ${year}`}
        placeholder="Fringe benefits based on total stipend support "
      />
    </div>
    <div className={styles.field}>
      <Input
        name="fringeBenefitForGraduatesStudents"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.fringeBenefitForGraduateStudents}
        label={`Fringe Benefits For Graduate Students Tuition in Year ${year} (After Inflation)`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Fringe benefits for graduate students tuition"
      />
    </div>
    <div className={styles.lastField}>
      <Input
        name="totalAmountAfterInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalAmountAfterInflation}
        label={`Total Amount Requested for All Graduate Students (Stipends + Tuition + Stipend Based Fringe Benefits) in Year ${year} After Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total amount requested for all graduate students after inflation"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="percentForAccountOwner"
        type="number"
        disabled={!owner}
        errors={errors.percentForAccountOwner}
        onBlur={onBlur}
        value={data.percentForAccountOwner}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ percentForAccountOwner: event.target.value })
        }
        className={styles.fullInput}
        help={`The field is available if the "Account owner is a graduate student" switch is enabled. Please indicate the percentage of compensation that should be allocated to the account owner. Based on this and the amount of compensation after inflation, we will be able to calculate the account owner's salary budget and display it on the dashboard.`}
        label={`What percentage of compensation should be allocated to account owner?`}
        placeholder="Enter a percentage of compensation for account owner"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="totalAmountForAccountOwnerAfterInflation"
        disabled
        readOnly
        className={styles.fullInput}
        money
        value={owner ? data.totalAmountForAccountOwnerAfterInflation : ""}
        label={`Total Amount Requested for account owner (Stipends + Tuition + Stipend Based Fringe Benefits) in Year ${year} After Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total Amount Requested for account owner after inflation"
      />
    </div>
    <div className={styles.formFooter}>
      <Button loading={loading} htmlType="submit" name="submit" size="xl">
        Save
      </Button>
    </div>
  </Form>
);

const TermForm: FC<FormProps> = ({
  trackError,
  trackExit,
  trackFormStarted,
  onSubmit,
  loading,
  year,
  owner,
  errors = {},
  data,
  onBlur,
  onChange,
}) => (
  <Form
    prompt
    onSubmit={onSubmit}
    trackError={trackError}
    trackExit={trackExit}
    trackFormStarted={trackFormStarted}
  >
    <div className={styles.field}>
      <Input
        name="numberOfStudents"
        type="number"
        errors={errors.numberOfStudentsSummer}
        onBlur={onBlur}
        value={data.numberOfStudents}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ numberOfStudents: event.target.value })
        }
        className={styles.fullInput}
        help="This does not include the summer. For example, if you are on the semester system and a graduate student will be supported for one semester, they will count as 0.5 of a graduate student because they are only going to be working for half of a whole academic year. If you are using trimesters/quarters and a grad student is working for 1 academic term, count this person as 0.33 of a grad student”"
        label={`Number of Graduate Students During the Whole Academic Year for Year ${year}`}
        required
        placeholder="Number of graduate students during the whole academic year "
      />
    </div>
    <div className={styles.field}>
      <Input
        name="numberOfStudentsSummer"
        type="number"
        errors={errors.numberOfStudentsSummer}
        onBlur={onBlur}
        value={data.numberOfStudentsSummer}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ numberOfStudentsSummer: event.target.value })
        }
        className={styles.fullInput}
        help="If a graduate student will only be supported for a portion of the summer, then count them as a portion of a graduate student. Assume there are 3 months in the summer. If a graduate student will be compensated for one month, they would count as 0.33 of a graduate student because they will be working 1/3rd of the summer"
        label={`Number of Graduate Students During the Summer for Year ${year}`}
        required
        placeholder="Number of graduate students during the summer"
      />
    </div>

    <div className={cx(styles.field, styles.twoInputsField)}>
      <Input
        name="graduateStudentStipendsDuringTerm"
        money
        disabled
        readOnly
        className={styles.input}
        value={data.graduateStudentStipendsDuringTerm}
        label={`Graduate student stipends during the academic terms for Year ${year}`}
        placeholder="Graduate student stipends during the academic terms"
      />
      <Input
        name="graduateStudentStipendsDuringSummer"
        money
        disabled
        readOnly
        className={styles.input}
        value={data.graduateStudentStipendsDuringSummer}
        label={`Graduate student stipends during the summer for Year ${year}`}
        placeholder="Graduate student stipends during the summer"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="graduateStudentStipendsDuringTermAfterInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.graduateStudentStipendsDuringTermAfterInflation}
        label={`Graduate student stipends during the academic terms for Year ${year} after inflation`}
        placeholder="Graduate student stipends during the academic terms"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="graduateStudentStipendsDuringSummerAfterInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.graduateStudentStipendsDuringSummerAfterInflation}
        label={`Graduate student stipends during the summer for Year ${year} after inflation`}
        placeholder="Graduate student stipends during the summer"
      />
    </div>

    <div className={styles.lastField}>
      <Input
        name="totalStipendAfterInflation"
        money
        disabled
        readOnly
        className={styles.fullInput}
        value={data.totalStipendAfterInflation}
        label={`Total Stipend for academic and summer terms for Year ${year} after inflation`}
        placeholder="Total Stipend for academic and summer terms"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="numberToReceiveTuitionCoverage"
        type="number"
        errors={errors.numberToReceiveTuitionCoverage}
        onBlur={onBlur}
        value={data.numberToReceiveTuitionCoverage}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ numberToReceiveTuitionCoverage: event.target.value })
        }
        className={styles.fullInput}
        help="This does not have to be a whole number. For example, if one graduate student will receive tuition support for only half of the whole academic year, count them as 0.5 of a graduate student. A second example is that if you are on the trimester system and a graduate student will only receive tuition support for one trimester in the academic year for this year, they would count as 0.33 of a graduate student. Not all of the graduate students who are getting stipends need to get tuition support or vice versa"
        label={`Number of Graduate Students to Receive full Tuition coverage for the whole Academic Year`}
        required
        placeholder="Enter number of graduate students to receive tuition"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="totalTuitionBeforeInflation"
        money
        value={data.totalTuitionBeforeInflation}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Total Amount Requested for Tuition for All Graduate Students in Year ${year} Before Inflation`}
        placeholder="Total tuition before inflation"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="totalTuitionAfterInflation"
        money
        value={data.totalTuitionAfterInflation}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Total Amount Requested for Tuition for All Graduate Students in Year After Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total tuition after inflation"
      />
    </div>
    <div className={styles.lastField}>
      <Input
        name="totalAmountForGraduateStudentsAfterInflation"
        money
        value={data.totalAmountForGraduateStudentsAfterInflation}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Total Amount Requested For All Graduate Student Stipends and Tuition in Year After Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total amount requested for all graduate students after inflation"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="fringeBenefitBasedOnStipend"
        money
        value={data.fringeBenefitBasedOnStipend}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Fringe Benefits For Graduate Students Based on Total Stipend Support in Year ${year}`}
        placeholder="Fringe benefits based on total stipend support "
      />
    </div>
    <div className={styles.field}>
      <Input
        name="fringeBenefitBasedOnStipendAfterInflation"
        money
        value={data.fringeBenefitBasedOnStipendAfterInflation}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Fringe Benefits For Graduate Students Tuition in Year ${year} (After Inflation)`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Fringe benefits for graduate students tuition"
      />
    </div>
    <div className={styles.lastField}>
      <Input
        name="totalAmountAfterInflation"
        money
        value={data.totalAmountAfterInflation}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Total Amount Requested for All Graduate Students (Stipends + Tuition + Stipend Based Fringe Benefits) in Year ${year} After Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total amount requested for all graduate students after inflation"
      />
    </div>

    <div className={styles.field}>
      <Input
        name="percentForAccountOwner"
        type="number"
        errors={errors.percentForAccountOwner}
        onBlur={onBlur}
        value={data.percentForAccountOwner}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange({ percentForAccountOwner: event.target.value })
        }
        className={styles.fullInput}
        disabled={!owner}
        help={`The field is available if the "Account owner is a graduate student" switch is enabled. Please indicate the percentage of compensation that should be allocated to the account owner. Based on this and the amount of compensation after inflation, we will be able to calculate the account owner's salary budget and display it on the dashboard.`}
        label={`What percentage of compensation should be allocated to account owner?`}
        placeholder="Enter a percentage of compensation for account owner"
      />
    </div>
    <div className={styles.field}>
      <Input
        name="totalAmountForAccountOwnerAfterInflation"
        money
        value={owner ? data.totalAmountForAccountOwnerAfterInflation : ""}
        className={styles.fullInput}
        disabled
        readOnly
        label={`Total Amount Requested for account owner (Stipends + Tuition + Stipend Based Fringe Benefits) in Year ${year} After Inflation`}
        help={
          year === 1
            ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
            : undefined
        }
        placeholder="Total Amount Requested for account owner after inflation"
      />
    </div>

    <div className={styles.formFooter}>
      <Button htmlType="submit" loading={loading} name="submit" size="xl">
        Save
      </Button>
    </div>
  </Form>
);

const GraduatedStudentsCompensation: FC = () => {
  const params: Record<string, string> = useParams();
  const { grant } = useGrants();
  const {
    loading,
    errors,
    updateGraduatedStudentAnnualInfo,
    calculateGraduatedStudentByAnnual,
    calculateGraduatedStudentByTerm,
    trackFormStarted,
    trackError,
    trackExit,
  } = usePersonnel();
  const annualInfo = grant.graduateStudents.annualInfo[Number(params.year)];
  const [data, onChangeData] = useState({
    numberToReceiveStipends: annualInfo?.numberToReceiveStipends ?? undefined,
    numberToReceiveTuitionCoverage:
      annualInfo?.numberToReceiveTuitionCoverage ?? undefined,
    percentForAccountOwner: annualInfo?.percentForAccountOwner
      ? percentToFixed(annualInfo.percentForAccountOwner)
      : undefined,
  });
  const [termData, onChangeTermData] = useState({
    numberOfStudents: annualInfo?.numberOfStudents ?? undefined,
    numberOfStudentsSummer: annualInfo?.numberOfStudentsSummer ?? undefined,
    numberToReceiveTuitionCoverage:
      annualInfo?.numberToReceiveTuitionCoverage ?? undefined,
    percentForAccountOwner: annualInfo?.percentForAccountOwner
      ? percentToFixed(annualInfo.percentForAccountOwner)
      : undefined,
  });

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data]
  );

  const handleChangeTermData = useCallback(
    (field: Record<string, any>) => {
      onChangeTermData({
        ...termData,
        ...field,
      });
    },
    [termData]
  );

  const onBlurTermForm = useCallback(() => {
    const annualInfo = grant.graduateStudents.annualInfo[Number(params.year)];
    const canCalculate = every(
      [termData.numberOfStudents, termData.numberOfStudentsSummer],
      (i: any) => i !== undefined
    );
    if (canCalculate) {
      calculateGraduatedStudentByTerm(
        grant.id,
        {
          numberOfStudents: termData.numberOfStudents,
          serialNumber: annualInfo.serialNumber || Number(params.year) + 1,
          numberOfStudentsSummer: termData.numberOfStudentsSummer,
          percentForAccountOwner: termData.percentForAccountOwner
            ? Number(termData.percentForAccountOwner) / 100
            : undefined,
          year: annualInfo.year,
          numberToReceiveTuitionCoverage:
            termData.numberToReceiveTuitionCoverage,
        },
        (data: T.GraduatedStudentCalcByTerm) => {
          onChangeTermData({
            ...termData,
            ...data,
            percentForAccountOwner: data.percentForAccountOwner
              ? percentToFixed(data.percentForAccountOwner)
              : undefined,
          });
        }
      );
    }
  }, [grant, calculateGraduatedStudentByTerm, termData, params]);

  const onBlurAnnualForm = useCallback(() => {
    const annualInfo = grant.graduateStudents.annualInfo[Number(params.year)];
    if (data.numberToReceiveStipends != undefined) {
      calculateGraduatedStudentByAnnual(
        grant.id,
        {
          numberToReceiveStipends: data.numberToReceiveStipends,
          serialNumber: annualInfo.serialNumber || Number(params.year) + 1,
          numberToReceiveTuitionCoverage: data.numberToReceiveTuitionCoverage,
          percentForAccountOwner: data.percentForAccountOwner
            ? Number(data.percentForAccountOwner) / 100
            : undefined,
          year: annualInfo.year,
        },
        (cbData: T.GraduatedStudentCalcByAnnual) => {
          onChangeData({
            ...data,
            ...cbData,
            percentForAccountOwner: cbData.percentForAccountOwner
              ? percentToFixed(cbData.percentForAccountOwner)
              : undefined,
          });
        }
      );
    }
  }, [grant, calculateGraduatedStudentByAnnual, data, params]);

  useEffect(() => {
    onBlurTermForm();
    onBlurAnnualForm();
  }, [grant.id]);

  const title = useMemo(() => {
    if (grant.graduateStudents.stipendAwardPeriod === "academicTerm") {
      return "Graduate Students which are supported by academic term";
    }
    return "Graduate Students which are supported annually";
  }, [grant]);

  const onSubmit = useCallback(() => {
    const submitData =
      grant.graduateStudents.stipendAwardPeriod === "academicTerm"
        ? termData
        : data;
    const annualInfo = grant.graduateStudents.annualInfo[Number(params.year)];
    updateGraduatedStudentAnnualInfo(
      grant.id,
      {
        ...submitData,
        serialNumber: annualInfo.serialNumber,
        percentForAccountOwner: submitData.percentForAccountOwner
          ? submitData.percentForAccountOwner / 100
          : undefined,
        year: annualInfo.year,
      },
      grant.graduateStudents.stipendAwardPeriod as string,
      grant.graduateStudents.isAccountOwner
    );
  }, [grant, params, updateGraduatedStudentAnnualInfo, data, termData]);

  return (
    <GrantFormWrapper
      title={title}
      subtitle={`Year ${Number(params.year) + 1}`}
    >
      {grant.graduateStudents.stipendAwardPeriod === "academicTerm" ? (
        <TermForm
          owner={grant.graduateStudents.isAccountOwner}
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
          onBlur={onBlurTermForm}
          data={termData}
          onChange={handleChangeTermData}
          year={Number(params.year) + 1}
          trackError={trackError}
          trackExit={trackExit}
          trackFormStarted={trackFormStarted}
        />
      ) : (
        <AnnuallyForm
          owner={grant.graduateStudents.isAccountOwner}
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
          onBlur={onBlurAnnualForm}
          data={data}
          onChange={handleChangeData}
          year={Number(params.year) + 1}
          trackError={trackError}
          trackExit={trackExit}
          trackFormStarted={trackFormStarted}
        />
      )}
    </GrantFormWrapper>
  );
};

export default GraduatedStudentsCompensation;
