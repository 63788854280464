import { isNaN } from "lodash";
import numeral from "numeral";

export const percentToFixed = (
  value: string | number = 0,
  decimals: number = 2
): any => {
  const number = Number(value);
  if (isNaN(number)) return 0;
  return Number(number * Math.pow(10, 2)).toFixed(decimals);
};

export const numberToMoney = (
  value: string | number | undefined,
  format?: string
): string => {
  if (value === "" || value === null || value === undefined) return "";
  return `$${numeral(value).format(format ?? "0,0.00")}`;
};
