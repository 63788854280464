import { FC, useCallback, useMemo } from "react";
import Typo from "components/typo";
import CreateBtn from "pages/grant/paper/card/create";
import styles from "./payments.module.css";
import useUrl from "hooks/useUrl";
import {
  Payment as PaymentType,
  PaymentYear,
} from "store/grants/otherDirectCosts/Payments/types";
import useIncentivePayments from "hooks/useIncentivePayments";
import dayjs from "dayjs";
import Item from "pages/grant/paper/card/item";
import Anchor, { TotalAnchor } from "pages/grant/paper/card/anchor";
import { FullGrant } from "store/grants/types";

type Props = {
  side: FullGrant;
};

const OtherCostIncentivePayments: FC<Props> = ({ side }) => {
  const url = useUrl();
  const { loading, deletePayment, createPayment } = useIncentivePayments();

  const handleDeletePayment = useCallback(
    (id?: string) => {
      if (id) deletePayment(side.id, id, url.categoryId === id);
    },
    [deletePayment, url, side]
  );

  const list = useMemo(() => {
    return side.otherDirectCosts.incentivePayments.map((item: PaymentType) => {
      const links = [
        {
          label: "Payment info",
          to: `/grants/${side.id}/otherDC/payments/${item.id}/edit`,
          active: url.categoryId === item.id && url.section === "edit",
        },
        ...item.years.map((yearItem: PaymentYear, idx: number) => ({
          label: `${dayjs(yearItem.year[0]).format("M/D/YYYY")} - ${dayjs(
            yearItem.year[1]
          ).format("M/D/YYYY")}`,
          to: `/grants/${side.id}/otherDC/payments/${item.id}/year/${idx}`,
          active:
            url.categoryId === item.id &&
            url.section === "year" &&
            url.sectionId === `${idx}`,
          isDisabled:
            !item.name || typeof item.isInflationAppliedEachYear !== "boolean",
        })),
      ];

      return (
        <Item
          title={item.name || "Untitled payment"}
          onDelete={() => handleDeletePayment(item.id)}
          links={links}
          key={item.id}
          active={url.categoryId === item.id}
        />
      );
    });
  }, [side, handleDeletePayment, url]);
  return (
    <>
      <Anchor
        thin
        to={`/grants/${side.id}/otherDC/payments/general-info`}
        active={
          url.category === "otherDC" &&
          url.subCategory === "payments" &&
          url.categoryId === "general-info"
        }
      >
        General info
      </Anchor>
      {list.length > 0 ? (
        list
      ) : (
        <Typo className={styles.placeholder} size={14} type="div">
          No payments have been created yet
        </Typo>
      )}
      {list.length > 0 ? (
        <TotalAnchor
          total={side.otherDirectCosts.incentivePaymentsTotalAmount}
          to={`/grants/${side.id}/otherDC/payments/totals`}
          active={url.categoryId === "totals" && url.subCategory === "payments"}
        >
          Totals
        </TotalAnchor>
      ) : null}
      <CreateBtn
        loading={loading}
        onClick={() => createPayment(side.id)}
        name="add-grant-payments"
        side={side}
        tip="To add payment, you must save general info first"
      >
        Add payment
      </CreateBtn>
    </>
  );
};

export default OtherCostIncentivePayments;
