import { Button, Input } from "components/form";
import styles from "./signUp.module.css";
import { ChangeEvent, useState } from "react";
import { useMutation } from "react-query";
import client from "helpers/apiClient";
import formStyles from "components/form/form.module.css";

const PREDEFINED_OPTIONS = ["Internet", "Collegue", "Trade Fair", "Other"];

export function HeardOfPage({
  onComplete,
  email,
}: {
  onComplete: () => void;
  email: string;
}) {
  const [budget1, setBudget1] = useState("");
  const [budget2, setBudget2] = useState("");
  const [budget3, setBudget3] = useState("");
  const [budget4, setBudget4] = useState("");
  const [otherFeedback, setOtherFeedback] = useState("");
  const [foundOption, setFoundOption] = useState<string>();
  const sendFeedback = useMutation({
    mutationFn: async (feedback: string) => {
      await client.patch("/users/feedback", {
        email,
        feedback,
        budgets: [budget1, budget2, budget3, budget4],
      });
    },
  });

  return (
    <div
      style={{
        boxSizing: "border-box",
      }}
    >
      <h2>How did you found out about us</h2>
      <p>On average, how many NSF grant budgets do you prepare annually?</p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1em",
          paddingBottom: "1em",
        }}
      >
        <input
          className={formStyles.input}
          placeholder="Budget 1"
          value={budget1}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setBudget1(event.target.value);
          }}
        />
        <input
          className={formStyles.input}
          placeholder="Budget 2"
          value={budget2}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setBudget2(event.target.value);
          }}
        />
        <input
          className={formStyles.input}
          placeholder="Budget 3"
          value={budget3}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setBudget3(event.target.value);
          }}
        />
        <input
          className={formStyles.input}
          placeholder="Budget 4"
          value={budget4}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setBudget4(event.target.value);
          }}
        />
      </div>

      <div>
        <ul className={styles.heardoflist}>
          {PREDEFINED_OPTIONS.map((option) => (
            <li
              key={option}
              className={option === foundOption ? styles.active : ""}
              onClick={() => setFoundOption(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          paddingTop: "1em",
        }}
      >
        <input
          className={formStyles.input}
          placeholder="Tell us more ..."
          value={otherFeedback}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setOtherFeedback(event.target.value);
          }}
        />
      </div>
      <div className={styles.continueBtn}>
        <Button
          htmlType="button"
          onClick={async () => {
            if (foundOption) {
              await sendFeedback.mutateAsync(
                `${foundOption}: ${otherFeedback}`
              );
            }
            onComplete();
          }}
          name="save"
          size="lg"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
