import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { PublicHeader } from "containers/header";
import Typo from "components/typo";
import CollapseInfo from "components/collapseInfo";
import qr from "./qr.svg";
import qrLogo from "./qrLogo.svg";
import styles from "./complete.module.css";
import useSubawards, { PublicSubAward } from "hooks/useSubawards";
import { useParams } from "react-router-dom";
import Spinner from "components/spinner";
import { Form, Input, Button, Autocomplete } from "components/form";
import Field from "components/field";
import useOrganizations from "hooks/useOrganizations";
import SubawardeeTable from "containers/subawardee";
import dayjs from "dayjs";
import Icon from "components/icon";
import { debounce } from "lodash";
import Tip from "components/tip";
import { useMixPanel } from "../../hooks/useMixPanel";

type Info = {
  title: string;
  text: string | ReactElement;
};

type Props = {
  data: PublicSubAward;
  onSubmit: (data: PublicSubAward) => void;
};

const info: Info[] = [
  {
    title: "Why do I need to complete this template to share my budget?",
    text: "The researcher who you are collaborating with is using Budget Mentor to create their NSF budget. Budget Mentor is a new online software tool available to help researchers draft NSF budgets. In the step where your collaborator needs to incorporate subawards, your collaborator has exported this template and sent it to you. Once you finish filling in these values and return this file to them, your collaborator will drag this file into their subawards section in their Budget Mentor grant and you as a subawardee, and the values on this spreadsheet will automatically be incorporated into their budget. Your help filling out this template tremendously helps your collaborator seamlessly incorporate your numbers into their final budget. Please fill out the gray inputs. The green cells and the blue cells are automatically calculated based on numbers input into your gray cells. Please don't alter or move cells around when filling out this template because this will misalign values when your collaborator uploads them.",
  },
  {
    title: "What is Budget Mentor?",
    text: "Budget Mentor has a user-friendly interface to help researchers move through the steps of creating each section of their NSF research budget. Budget Mentor keeps track of inflation, and direct and indirect costs, and helps you automatically determine what you likely want to select to contribute to Total Modified Direct Costs. Budget Mentor provides you with the information needed to create an NSF budget as you need it. Once you complete your grant budget on Budget Mentor you can export this exact same form with precise MTDC & Indirect Cost calculations completed. If you need your full budget, this will also be available to you. If you need to revise numbers, your budget will automatically update allowing you to easily make changes to each section. ",
  },
  {
    title: "Can I draft a final version of this template with Budget Mentor?",
    text: "Yup! We recommend this. Regardless of if you have a finished budget planned or you aren't even sure where to start, moving your research budget planning into Budget Mentor will make it easy to understand what is expected of you in each category and make revisions easy and automatic. Once you complete your grant budget you will be able to export a complete version of this form (Budget Mentor will work with all of your numbers automatically to help you get the most accurate values for this form), your whole budget from top to bottom, and an NSF 1030 budget form. To be transparent, Budget Mentor enables you to try the software for free by allowing you to draft the first few personnel sections of an NSF budget however to unlock the final forms on Budget Mentor, we'd appreciate you joining Budget Mentor and helping us stay afloat. ",
  },
  {
    title: "Where can I go to try Budget Mentor to create my subaward budget?",
    text: (
      <>
        Please use the website to create the subaward budget:{" "}
        <a target="_blank" rel="noreferrer" href="https://budget-mentor.com">
          Budget-Mentor.com
        </a>
        .
      </>
    ),
  },
];

const ForbiddenPage: FC = () => {
  return (
    <>
      <PublicHeader />
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.errorTexts}>
            <Typo type="h3" className={styles.title}>
              Subawardees form is no longer available
            </Typo>
            <Typo className={styles.errorText} size={20}>
              Unfortunately, the link to this page is no longer active. Please
              contact the grant's primary investigator for more information.
            </Typo>
          </div>
        </div>
      </div>
    </>
  );
};

const SuccessPage: FC = () => {
  return (
    <>
      <PublicHeader />
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.errorTexts}>
            <Typo type="h3" className={styles.title}>
              Subawardees form has been successfully submitted{" "}
            </Typo>
            <Typo className={styles.errorText} size={20}>
              Primary Investigator of the grant will receive an email
              notification about a successful form submission. To resubmit the
              form, please contact the grant's Primary Investigator.
            </Typo>
          </div>
        </div>
      </div>
    </>
  );
};

const CompleteSubAward: FC<Props> = ({ data, onSubmit }) => {
  const { fetchApi, fetchOptions, isLoading } = useOrganizations();
  const [subRecipient, onChangeSubRecipient] = useState(data.subRecipient);
  const [subawardeeData, onChangeSubawardeeData] = useState(
    data.subAwardeeForm
  );
  const [copied, onChangeCopied] = useState<boolean>(false);

  const debouncedChangeCopied = useCallback(
    debounce(() => onChangeCopied(false), 2000, { trailing: true }),
    []
  );

  const handleChangeSubRecipient = useCallback(
    (field: Record<string, any>) => {
      onChangeSubRecipient({
        ...subRecipient,
        ...field,
      });
    },
    [subRecipient]
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      ...data,
      subRecipient: subRecipient,
      subAwardeeForm: subawardeeData,
    });
  }, [onSubmit, data, subRecipient, subawardeeData]);

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      onChangeCopied(true);
      debouncedChangeCopied();
    });
  }, [debouncedChangeCopied]);

  const disabled = !data.isEditable;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/da83fe63-ffc7-40b8-8903-106ca3e85af2/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/731ef958-7af8-4e43-b98b-47f1b6b5fd95/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/b722c317-d49c-43be-bdce-df4e094dd234/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <PublicHeader />
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.header}>
            <div className={styles.texts}>
              <Typo type="h2" className={styles.title}>
                Form For Subawardees on {data.grant.title}
              </Typo>
              {disabled ? (
                <>
                  <div className={styles.placeholder}>
                    <Icon
                      icon="alert-triangle"
                      className={styles.placeholderIcon}
                    />
                    <div className={styles.placeholderTexts}>
                      You have opened the Subawardees form in preview mode. Here
                      you can see the data that the subrecipient provides
                      regarding the subaward. To share the link with a
                      subrecipient, please copy the
                      <span className={styles.copy}>
                        link
                        <Tip
                          className={styles.copyIcon}
                          text={copied ? "Copied" : "Copy link"}
                        >
                          <Button
                            onClick={onCopy}
                            name="subawards-copy-link"
                            htmlType="button"
                            type="link"
                            postIcon="link-03"
                          />
                        </Tip>
                      </span>
                    </div>
                  </div>
                </>
              ) : null}
              <Typo className={styles.text}>
                Your collaborator uses Budget Mentor, an online tool, to create
                an NSF budget. Once you complete this template and return it,
                they will use it in the subawards section of their Budget Mentor
                grant. Your input will be automatically incorporated into their
                budget, ensuring a seamless process.
              </Typo>
              {/* <Typo className={styles.red}>
                <>
                  Please note that this link is valid until{" "}
                  {dayjs(data.expiresAt).format("MMMM DD, YYYY")}.
                </>
              </Typo> */}
            </div>
            <div className={styles.qrWrapper}>
              <Typo size={13} className={styles.visit}>
                Visit our website to know more
              </Typo>
              <img src={qr} className={styles.qr} draggable={false} alt="qr" />
              <img
                src={qrLogo}
                className={styles.logo}
                draggable={false}
                alt="qr-logo"
              />
            </div>
          </div>

          <Typo type="h2" className={styles.title}>
            Budget Mentor is the tool of choice for NSF budget drafting by many
            universities including…
          </Typo>

          <div
            className="senja-embed"
            data-id="731ef958-7af8-4e43-b98b-47f1b6b5fd95"
            data-mode="shadow"
            data-lazyload="false"
            style={{ display: "block", marginTop: "2em" }}
          ></div>

          <div
            className="senja-embed"
            data-id="b722c317-d49c-43be-bdce-df4e094dd234"
            data-mode="shadow"
            data-lazyload="false"
            style={{ display: "block" }}
          ></div>
        </div>
      </div>
      <div className={styles.grey}>
        <div className="container">
          <Typo type="h4" className={styles.subtitle}>
            Helpful information for form recipient
          </Typo>
          {info.map((item: Info, idx: number) => (
            <CollapseInfo key={idx} className={styles.info} {...item} />
          ))}
        </div>
      </div>
      <div className="container">
        <div className={styles.section}>
          <Typo type="h3" className={styles.subtitle2}>
            Primary investigator information
          </Typo>
          <div className={styles.field}>
            <Typo size={18}>
              {data.primaryInvestigator.firstName}{" "}
              {data.primaryInvestigator.lastName}
            </Typo>
            <Typo size={16} className={styles.green} bold>
              {data.author.email}
            </Typo>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={styles.section2}>
          <Typo type="h3" className={styles.subtitle3}>
            Subrecipient information
          </Typo>
          <Typo size={20} className={styles.fill}>
            Please fill in this data
          </Typo>
          <Form onSubmit={handleSubmit}>
            <Field>
              <Input
                name="firstName"
                label="Name"
                required
                placeholder="Enter name"
                value={subRecipient.name}
                disabled={disabled}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChangeSubRecipient({ name: event.target.value })
                }
              />
              <Input
                name="email"
                label="Email"
                type="email"
                placeholder="Enter email"
                required
                value={subRecipient.email}
                disabled={disabled}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChangeSubRecipient({ email: event.target.value })
                }
              />
            </Field>
            <Field last>
              <Autocomplete
                value={subRecipient.primaryOrganization}
                onChange={(value: string | number) =>
                  handleChangeSubRecipient({ primaryOrganization: value })
                }
                name="primaryOrganization"
                options={fetchOptions}
                placeholder="Select organization"
                label="Organization"
                required
                disabled={disabled}
                onLoad={fetchApi}
                clearable
                isLoading={isLoading}
              />
              <Input
                name="department"
                label="Department"
                placeholder="Enter department"
                value={subRecipient.department}
                disabled={disabled}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChangeSubRecipient({ department: event.target.value })
                }
              />
            </Field>
            <SubawardeeTable
              disabled={disabled}
              data={subawardeeData}
              onChange={onChangeSubawardeeData}
            />
            <div className={styles.btnWrapper}>
              <Button htmlType="submit" disabled={disabled} name="submit">
                Submit a form
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div
        className="senja-embed"
        data-id="da83fe63-ffc7-40b8-8903-106ca3e85af2"
        data-mode="shadow"
        data-lazyload="false"
        style={{ display: "block" }}
      ></div>
    </>
  );
};

const CompleteSubAwardPage: FC = () => {
  const { pageViewed } = useMixPanel();
  const {
    getPublicSubaward,
    forbidden,
    submitted,
    publicSubaward,
    updatePublicSubaward,
    loading,
  } = useSubawards();
  const params: Record<string, string> = useParams();

  useEffect(() => {
    if (params.token) getPublicSubaward(params.token);
    //track mix panel Page Viewed event
    pageViewed("Complete Sub Award");
  }, []);

  const onSubmit = (data: PublicSubAward) => {
    updatePublicSubaward(params.token, {
      ...data,
      subAwardeeForm: {
        ...data.subAwardeeForm,
        rates: data.subAwardeeForm.rates.map((item) => ({
          ...item,
          rate: item.rate ? Number(item.rate) / 100 : item.rate,
        })),
      },
    });
  };

  return (
    <>
      {forbidden ? (
        <ForbiddenPage />
      ) : (
        <CompleteSubAward
          onSubmit={onSubmit}
          key={publicSubaward.author.email}
          data={publicSubaward}
        />
      )}
      {loading ? <Spinner full className={styles.spinner} /> : null}
    </>
  );
};

export default CompleteSubAwardPage;
