import { ChangeEvent, FC, useCallback } from "react";
import cx from "classnames";
import { sum } from "lodash";
import dayjs from "dayjs";
import { numberToMoney } from "helpers/numbers";
import styles from "./subawardee.module.css";
import Input from "./input";
import { Button } from "components/form";
import Icon from "components/icon";

type Year = {
  year: string[];
  serialNumber: number;
  directCost: string | number;
  indirectCost: string | number;
  modifiedDirectCost: string | number;
  fee: string | number;
};

type YearFieldToSum =
  | "directCost"
  | "indirectCost"
  | "modifiedDirectCost"
  | "fee";

type Rate = {
  name: string;
  rate: string | null | number;
  startDate: string | null;
  endDate: string | null;
};

type Data = {
  years: Array<Year>;
  rates: Array<Rate>;
};

type Props = {
  data: Data;
  disabled?: boolean;
  onChange: (value: Data) => void;
};

const SubawardeeTable: FC<Props> = ({ data, disabled, onChange }) => {
  const handleChangeYears = useCallback(
    (field: Record<string, any>, serialNumber: number) => {
      const newYears = data.years.map((item: Year) => {
        if (item.serialNumber === serialNumber) return { ...item, ...field };
        return item;
      });
      onChange({
        ...data,
        years: newYears,
      });
    },
    [data, onChange]
  );

  const handleChangeRates = useCallback(
    (field: Record<string, any>, idx: number) => {
      const newRates = data.rates.map((item: Rate, index: number) => {
        if (index === idx) return { ...item, ...field };
        return item;
      });
      onChange({
        ...data,
        rates: newRates,
      });
    },
    [data, onChange]
  );

  const addEmptyRate = useCallback(() => {
    onChange({
      ...data,
      rates: [
        ...data.rates,
        {
          name: "Rate " + (data.rates.length + 1),
          rate: "",
          startDate: null,
          endDate: null,
        },
      ],
    });
  }, [data, onChange]);

  const onDeleteRate = useCallback(
    (idx: number) => {
      if (data.rates.length < 2 || disabled) return;
      const newRates = data.rates.filter(
        (item: Rate, index: number) => index !== idx
      );
      onChange({
        ...data,
        rates: newRates,
      });
    },
    [data, disabled, onChange]
  );

  const getDirectIndirectSumForYear = useCallback(
    (serialNumber: number) => {
      const summ = data.years
        .filter((year: Year) => year.serialNumber === serialNumber)
        .reduce(
          (res: number, item: Year) =>
            sum([
              res,
              Number(item.indirectCost) || 0,
              Number(item.directCost) || 0,
            ]),
          0
        );
      return numberToMoney(summ);
    },
    [data]
  );

  const getAmountSumForYear = useCallback(
    (serialNumber: number) => {
      const summ = data.years
        .filter((year: Year) => year.serialNumber === serialNumber)
        .reduce(
          (res: number, item: Year) =>
            sum([
              res,
              Number(item.fee) || 0,
              Number(item.indirectCost) || 0,
              Number(item.directCost) || 0,
            ]),
          0
        );
      return numberToMoney(summ);
    },
    [data]
  );

  const getYearsSum = useCallback(
    (fields: YearFieldToSum[]) => {
      const summ = sum(
        data.years.map((item: Year) => {
          return fields.reduce(
            (res: number, field: YearFieldToSum) =>
              res + Number(item[field]) || 0,
            0
          );
        })
      );
      return numberToMoney(summ);
    },
    [data]
  );

  return (
    <>
      <div className={styles.rates}>
        <div className={styles.wrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <td className={styles.grey}>F&A Rate name</td>
                <td className={styles.grey}>F&A Rate, %</td>
                <td className={styles.grey}>F&A Start date</td>
                <td className={styles.grey}>F&A End date</td>
                <td className={styles.grey}></td>
              </tr>
            </thead>
            <tbody>
              {data.rates.map((item: Rate, idx: number) => (
                <tr key={idx}>
                  <td>Rate {idx + 1}</td>
                  <td className={cx(styles.cellInput)}>
                    <Input
                      value={item.rate}
                      type="number"
                      disabled={disabled}
                      name={`table-cell-rate-rate-${idx}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeRates({ rate: event.target.value }, idx)
                      }
                    />
                  </td>
                  <td className={cx(styles.cellInput)}>
                    <Input
                      value={item.startDate}
                      type="date"
                      disabled={disabled}
                      name={`table-cell-rate-startDate-${idx}`}
                      max="2099-12-31"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeRates(
                          { startDate: event.target.value },
                          idx
                        )
                      }
                    />
                  </td>
                  <td className={cx(styles.cellInput)}>
                    <Input
                      value={item.endDate}
                      type="date"
                      max="2099-12-31"
                      min={dayjs(item.startDate).format("YYYY-MM-DD")}
                      disabled={disabled}
                      name={`table-cell-rate-endDate-${idx}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeRates({ endDate: event.target.value }, idx)
                      }
                    />
                  </td>
                  <td className={cx(styles.center, styles.grey)}>
                    <Icon
                      icon="trash-01"
                      size={18}
                      disabled={disabled}
                      className={cx(styles.remove, {
                        [styles.disabled]: data.rates.length < 2 || disabled,
                      })}
                      onClick={() => onDeleteRate(idx)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.addRateWrapper}>
          <Button
            name="add-rate"
            htmlType="button"
            disabled={disabled}
            onClick={addEmptyRate}
            preIcon="plus-circle"
          >
            Add rate
          </Button>
        </div>
      </div>
      <div className={styles.wrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td colSpan={2} rowSpan={2} className={styles.grey} />
              {data.years.map((item: Year, idx: number) => (
                <td className={cx(styles.center, styles.grey)} key={idx}>
                  Year {item.serialNumber}
                </td>
              ))}
              <td rowSpan={2} className={cx(styles.center, styles.grey)}>
                Totals
              </td>
            </tr>
            <tr>
              {data.years.map((item: Year, idx: number) => (
                <td key={idx} className={cx(styles.center, styles.grey)}>
                  {dayjs(item.year[0]).format("M/D/YYYY")} -{" "}
                  {dayjs(item.year[1]).format("M/D/YYYY")}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2} className={styles.grey}>
                Direct costs
              </td>
              {data.years.map((item: Year, idx: number) => (
                <td
                  key={idx}
                  className={cx(styles.center, styles.cellInput, styles.grey)}
                >
                  <Input
                    value={item.directCost}
                    disabled={disabled}
                    name={`table-cell-year-directCost-${idx}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleChangeYears(
                        { directCost: event.target.value },
                        item.serialNumber
                      )
                    }
                  />
                </td>
              ))}
              <td className={cx(styles.center, styles.primary)}>
                {getYearsSum(["directCost"])}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.grey}>
                Modified Total Direct Costs
              </td>
              {data.years.map((item: Year, idx: number) => (
                <td
                  key={idx}
                  className={cx(styles.center, styles.cellInput, styles.grey)}
                >
                  <Input
                    value={item.modifiedDirectCost}
                    disabled={disabled}
                    name={`table-cell-year-modifiedDirectCost-${idx}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleChangeYears(
                        { modifiedDirectCost: event.target.value },
                        item.serialNumber
                      )
                    }
                  />
                </td>
              ))}
              <td className={cx(styles.center, styles.primary)}>
                {getYearsSum(["modifiedDirectCost"])}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.grey}>
                Indirect Costs
              </td>
              {data.years.map((item: Year, idx: number) => (
                <td key={idx} className={cx(styles.center, styles.cellInput)}>
                  <Input
                    value={item.indirectCost}
                    disabled={disabled}
                    name={`table-cell-year-indirectCost-${idx}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleChangeYears(
                        { indirectCost: event.target.value },
                        item.serialNumber
                      )
                    }
                  />
                </td>
              ))}
              <td className={cx(styles.center, styles.primary)}>
                {getYearsSum(["indirectCost"])}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.grey}>
                Direct & Indirect Costs
              </td>
              {data.years.map((item: Year, idx: number) => (
                <td key={idx} className={cx(styles.center, styles.primary)}>
                  {getDirectIndirectSumForYear(item.serialNumber)}
                </td>
              ))}
              <td className={cx(styles.center, styles.primary)}>
                {getYearsSum(["indirectCost", "directCost"])}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.grey}>
                Fees
              </td>
              {data.years.map((item: Year, idx: number) => (
                <td key={idx} className={cx(styles.cellInput)}>
                  <Input
                    value={item.fee}
                    disabled={disabled}
                    name={`table-cell-year-fee-${idx}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleChangeYears(
                        { fee: event.target.value },
                        item.serialNumber
                      )
                    }
                  />
                </td>
              ))}
              <td className={cx(styles.center, styles.primary)}>
                {getYearsSum(["fee"])}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.darkgrey}>
                Amount of this request
              </td>
              {data.years.map((item: Year, idx: number) => (
                <td key={idx} className={cx(styles.center, styles.darkgrey)}>
                  {getAmountSumForYear(item.serialNumber)}
                </td>
              ))}
              <td className={cx(styles.center, styles.darkgrey)}>
                {getYearsSum(["indirectCost", "directCost", "fee"])}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubawardeeTable;
