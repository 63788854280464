import { useCallback, useState } from "react";
import tableDataAdapter, { FinalTableData } from "./common/finalAdapter";
import JSPDF from "jspdf";
import autoTable, { applyPlugin } from "jspdf-autotable";
import { AxiosResponse } from "axios";
import * as T from "./requestTypes";
import useApi from "./useApi";
import toast from "components/toast";
import { trackUserAction } from "helpers/trackUserActions";

applyPlugin(JSPDF);

type SummaryCSVItem = {
  title: string;
  table: string[][];
};

type iUseFinalForm = {
  loading: boolean;
  downloadLoading: boolean;
  tableData: FinalTableData;
  getTable: (grantId: string) => void;
  exportTable: (
    grantId: string,
    type: T.FinalFormExportType,
    name: string
  ) => void;
  exportSummaryTable: (
    grantId: string,
    type: T.FinalFormExportType,
    name: string
  ) => void;
};

const useFinalForm = (): iUseFinalForm => {
  const api = useApi();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [downloadLoading, onChangeDownloadLoading] = useState<boolean>(false);
  const [tableData, onChangeTableData] = useState<FinalTableData>(
    tableDataAdapter()
  );

  const getTable = useCallback(
    (grantId: string) => {
      onChangeLoading(true);
      api
        .getFinalForm(grantId)
        .then((response: AxiosResponse) => {
          onChangeLoading(false);
          const correctData = tableDataAdapter(response.data);
          onChangeTableData(correctData);
        })
        .catch(() => {
          onChangeLoading(false);
        });
    },
    [api]
  );

  const exportTable = useCallback(
    (grantId: string, type: T.FinalFormExportType, name: string) => {
      onChangeDownloadLoading(true);
      const func =
        type === "pdf" ? api.exportFinalFormCSV : api.exportFinalForm;
      func(grantId, type === "pdf" ? "csv" : type)
        .then((response: AxiosResponse) => {
          if (type !== "pdf") {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${name}.${type}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            onChangeDownloadLoading(false);
          } else {
            const doc = new JSPDF();
            const lines: string[][] = (response.data ?? "")
              .split("\n")
              .map((item: string) => item.split(","));
            const header = lines.slice(1, 6);
            doc.setFontSize(11);
            doc.setFont("helvetica");
            const data = lines.slice(6);
            header.forEach((text: string[], idx: number) => {
              const [title, ...other] = text;
              const field = `${title}: ${other.join(" ")}`;
              doc.text(field, 14, 8 * (idx + 1));
            });
            //@ts-ignore
            doc.autoTable({
              body: data,
              startX: 10,
              startY: header.length * 9,
              headStyles: {
                fillColor: 0,
              },
              styles: {
                cellPadding: 0.5,
                fontSize: 8,
                font: "helvetica",
                textColor: "#101828",
                lineColor: "#000",
                lineWidth: 0.1,
              },
              theme: "striped",
            });
            doc
              .save(`${name}.pdf`, { returnPromise: true })
              .then(() => {
                onChangeDownloadLoading(false);
              })
              .catch(() => {
                onChangeDownloadLoading(false);
                toast.error({
                  title: "Error",
                  message: "Something went wrong",
                });
              });
          }
        })
        .catch(() => {
          onChangeDownloadLoading(false);
          toast.error({
            title: "Error",
            message: "Something went wrong",
          });
        });
      trackUserAction("Clicks to export a form");
    },
    [api]
  );

  const exportSummaryTable = useCallback(
    (grantId: string, type: T.FinalFormExportType, name: string) => {
      onChangeDownloadLoading(true);
      const func =
        type === "csv"
          ? api.exportSummaryFinalFormCSV
          : api.exportSummaryFinalForm;
      func(grantId, type)
        .then((response: AxiosResponse) => {
          if (type === "csv") {
            const doc = new JSPDF({
              orientation: "landscape",
            });

            (response.data ?? []).forEach(
              (item: SummaryCSVItem, idx: number) => {
                if (idx > 0) {
                  const page = doc.getNumberOfPages();
                  doc.addPage();
                  doc.setPage(page + 1);
                }
                doc.setFontSize(11);
                doc.setFont("helvetica");
                doc.text(item.title, 14, 10);
                //@ts-ignore
                doc.autoTable({
                  body: item.table ?? [],
                  startY: 14,
                  pageBreak: "auto",
                  theme: "striped",
                  styles: {
                    cellPadding: 0.5,
                    fontSize: 8,
                    font: "helvetica",
                    textColor: "#101828",
                    lineColor: "#000",
                    lineWidth: 0.1,
                  },
                });
              }
            );
            doc
              .save(`${name}.pdf`, { returnPromise: true })
              .then(() => {
                onChangeDownloadLoading(false);
              })
              .catch(() => {
                onChangeDownloadLoading(false);
                toast.error({
                  title: "Error",
                  message: "Something went wrong",
                });
              });
            return;
          }
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${name}.${type}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          onChangeDownloadLoading(false);
        })
        .catch(() => {
          onChangeDownloadLoading(false);
          toast.error({
            title: "Error",
            message: "Something went wrong",
          });
        });
      trackUserAction("Clicks to export a summary proposal budget");
    },
    [api]
  );

  return {
    downloadLoading,
    exportTable,
    getTable,
    tableData,
    loading,
    exportSummaryTable,
  };
};

export default useFinalForm;
