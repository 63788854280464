import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import cx from "classnames";
import Icon from "components/icon";
import Typo from "components/typo";
import useGrants from "hooks/useGrants";
import useRates from "hooks/useRates";
import styles from "./warning.module.css";

export type RatePreviewResponse = {
  overlay: {
    startDate: string[];
    endDate: string[];
  };
  uncoveredPeriods: string[][];
};

type Props = {
  className?: string;
  borders?: boolean;
  onClose?: () => void;
};

const RatesWarning: FC<Props> = ({ className, onClose, borders }) => {
  const { grant } = useGrants();
  const {
    previewGrantRate,
    getGrantRatesPreviewFromStore,
    getGrantRatesFromStore,
    getGrantRates,
  } = useRates();
  const grantRates = getGrantRatesFromStore(grant.id);
  const globalTimeline = getGrantRatesPreviewFromStore(grant.id);
  const grantId = grant.id;

  useEffect(() => {
    getGrantRates(grantId);
    previewGrantRate(grantId, {});
  }, [grantId]);

  const link = `/grants/${grant.id}/facilities/rates`;
  const wrapperClassName = cx(styles.warning, className, {
    [styles.bordered]: borders,
  });
  if (grantRates.length < 1) {
    return (
      <div className={wrapperClassName}>
        {onClose ? (
          <Icon icon="x-close" onClick={onClose} className={styles.cross} />
        ) : null}
        <div className={styles.warningTitle}>
          <Icon
            className={styles.warningIcon}
            size={20}
            icon="alert-triangle"
          />
          <Typo className={styles.warningText}>
            <>
              Indirect costs can’t be displayed because F&A rates are absent.
              Follow the
              <Link className={styles.link} to={link}>
                link
              </Link>
              to add F&A rates and Indirect costs will be shown.
            </>
          </Typo>
        </div>
      </div>
    );
  }
  if (grant && globalTimeline.uncoveredPeriods.length > 0) {
    const s = globalTimeline.uncoveredPeriods.length > 1 ? "s" : "";
    return (
      <div className={wrapperClassName}>
        {onClose ? (
          <Icon icon="x-close" onClick={onClose} className={styles.cross} />
        ) : null}
        <div className={styles.warningTitle}>
          <Icon
            className={styles.warningIcon}
            size={20}
            icon="alert-triangle"
          />
          <Typo className={styles.warningText}>
            <>
              There are no F&A rate{s} for the period{s} below. Follow the
              <Link className={styles.link} to={link}>
                link
              </Link>
              to add F&A rates and Indirect costs will be shown
            </>
          </Typo>
        </div>
        <ul className={styles.uncovered}>
          {globalTimeline.uncoveredPeriods.map(
            (dates: string[], idx: number) => (
              <li key={idx}>
                <Typo className={styles.li}>
                  Rate is missed from {dayjs(dates[0]).format("M/D/YYYY")} to{" "}
                  {dayjs(dates[1]).format("M/D/YYYY")}
                </Typo>
              </li>
            )
          )}
        </ul>
      </div>
    );
  }
  return null;
};

export default RatesWarning;
