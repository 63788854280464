import {
  AllCommunityModule,
  ColDef,
  iconSetQuartzLight,
  ModuleRegistry,
  themeQuartz,
  ValueFormatterParams,
} from "ag-grid-community";
import {
  RowGroupingModule,
  RowGroupingPanelModule,
  TreeDataModule,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { labels } from "containers/collapseTable/final";
import { CommentedItem } from "containers/collapseTable/indirect";
import dayjs from "dayjs";
import {
  TableData,
  TableDataItem,
  TableSections,
} from "hooks/common/mtdcAdapter";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { IndirectCommentModal } from "./IndirectCommentModal";

ModuleRegistry.registerModules([
  AllCommunityModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  TreeDataModule,
]);

const DISABLED_ROWS = [
  "undergraduateStudentsAcademic",
  "undergraduateStudentsSummer",
  "graduateStudentsStipend",
  "graduateStudentsAcademic",
  "graduateStudentsSummer",
  "graduateStudentsStipend",
  "graduateStudentsTuition",
];

type Section = {
  name: string;
  sections?: TableSections;
  totalsItem?: TableDataItem;
};

const customThemeConfig = themeQuartz.withPart(iconSetQuartzLight).withParams({
  accentColor: "#31D5C8",
  borderColor: "#0000003D",
  browserColorScheme: "light",
  fontFamily: {
    googleFont: "Inter",
  },
  fontSize: 16,
  headerBackgroundColor: "#3E5475",
  headerFontSize: 16,
  headerFontWeight: 600,
  headerTextColor: "#FFFFFF",
});

function getSectionData(section: Section) {
  const rows = [];

  let item: Record<string, any> = {
    id: section.name,
    title: section.name,
    section: section.name,
    subSection: "",
    total: section.totalsItem?.totals,
    path: [section.name],
  };

  section.totalsItem?.years.forEach((i, index) => {
    item["year" + index] = i.value;
  });

  if (!section.totalsItem) {
    for (const key in section.sections) {
      const subSection = section.sections[key];

      subSection.totalsItem?.years.forEach((i, index) => {
        item["year" + index] = i.value;
      });

      item.total = subSection.totalsItem.totals;
      break;
    }
  }

  rows.push(item);

  for (const key in section.sections) {
    const subSection = section.sections[key];

    const title = labels[subSection.name] || subSection.name;

    if (!title) {
      break;
    }

    let item: Record<string, any> = {
      id: subSection.name,
      title,
      section: section.name,
      subSection: subSection.name,
      total: subSection.totalsItem.totals,
      path: [section.name, subSection.name],
      showMTDC: true,
      isMTDC: section.totalsItem?.isMTDC,
    };

    subSection.totalsItem.years.forEach((i, index) => {
      item["year" + index] = i.value;
    });
    rows.push(item);
  }
  return rows;
}

function useRowData(data: TableData) {
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [bottomRow, setBottomRow] = useState<any[]>([]);

  useEffect(() => {
    let totalRow: Record<string, any> = {
      section: "Total",
      title: data.totalsItem.name,
      subSection: data.totalsItem.name,
      total: data.totalsItem.totals,
      path: ["Total"],
    };
    data.totalsItem.years.forEach((item, index) => {
      totalRow["year" + index] = item.value;
    });

    setBottomRow([totalRow]);

    setRowData([
      ...getSectionData(data.sections.personnel),
      ...getSectionData(data.sections.otherPersonnel),
      ...getSectionData(data.sections.fringeBenefits),
      ...getSectionData(data.sections.travel),
      ...getSectionData(data.sections.participantSupport),
      ...getSectionData(data.sections.equipment),
      ...getSectionData(data.sections.otherDirectCosts),
    ]);

    setColDefs([
      ...data.years.map((item, index) => ({
        headerName: `Year ${index + 1}`,
        lockPosition: true,
        children: [
          {
            headerName: dayjs(item.year[0]).format("M/D/YYYY"),
            field: "year" + index,
            colSpan: () => 2,
            width: 100,
            lockPosition: true,
            type: "rightAligned",
            valueFormatter: ({ value }: ValueFormatterParams) =>
              value == null ? "" : numeral(value).format("$0,0.00"),
          },
          {
            headerName: dayjs(item.year[1]).format("M/D/YYYY"),
            lockPosition: true,
            width: 100,
          },
        ],
      })),
      {
        headerName: "Total",
        field: "total",
        pinned: "right",
        lockPosition: true,
        width: 120,
        type: "rightAligned",
        valueFormatter: ({ value }: ValueFormatterParams) =>
          value == null ? "" : numeral(value).format("$0,0.00"),
      },
      {
        headerName: "Attributed to MTDC",
        field: "isMTDC",
        pinned: "right",
        lockPosition: true,
        width: 120,
        editable: true,
        cellRendererSelector: (params) => {
          if (!!params.data.showMTDC) {
            return {
              component: "agCheckboxCellRenderer",
              params: {
                disabled: DISABLED_ROWS.includes(params.data.id),
              },
            };
          }
          return undefined;
        },
      },
    ]);
  }, [data]);

  return { rowData, colDefs, bottomRow };
}

export function MdtcTable({
  data,
  height = 400,
}: {
  data: TableData;
  height?: number;
}) {
  const { rowData, colDefs, bottomRow } = useRowData(data);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      pinned: "left",
      lockPosition: true,
      field: "title",
      minWidth: 320,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  return (
    <div
      // define a height because the Data Grid will fill the size of the parent container
      style={{ height }}
    >
      <AgGridReact<any>
        // @ts-ignore
        autoGroupColumnDef={autoGroupColumnDef}
        groupDisplayType="singleColumn"
        rowData={rowData}
        columnDefs={colDefs}
        treeData
        getDataPath={(data) => data.path}
        pinnedBottomRowData={bottomRow}
        theme={customThemeConfig}
        getRowId={(param) => param.data.id}
      />
    </div>
  );
}
