import Modal from "components/modal";
import Typo from "components/typo";
import React, {
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import icons from "./icons.png";
import budgetMentorIcon from "./budgetmentor-token.png";
import shareIcon from "./share-06.png";
import copyIcon from "./copy-01.png";

import formStyles from "components/form/form.module.css";
import styles from "./invitationModal.module.css";
import cx from "classnames";
import toast from "components/toast";
import { useMutation } from "react-query";
import client from "helpers/apiClient";
import { XIcon } from "lucide-react";

type Invitation = {
  id: string;
  shareKey: string;
  numberOfPermissions: number;
  validUntil: string;
  isInfinitePermission: boolean;
};

export function InvitationModal({
  open,
  onOpenChanged,
}: {
  open: boolean;
  onOpenChanged: (a: boolean) => void;
}) {
  return (
    <Modal onCancel={() => onOpenChanged(false)} wide visible={open}>
      <InvitationForm />
    </Modal>
  );
}

function InvitationForm() {
  const [emails, setEmails] = useState<string[]>([]);
  const [invite, setInvite] = useState<Invitation>();

  const inviteCreate = useMutation({
    mutationFn: async () => {
      const response = await client.post("/grant-invitations");
      return response.data as Invitation;
    },
    onSuccess: (result: Invitation) => {
      setInvite(result);
    },
  });

  const updateInvitation = useMutation({
    mutationFn: async ({
      shareKey,
      isInfinitePermission,
      numberOfPermissions,
    }: {
      shareKey: string;
      numberOfPermissions: number;
      isInfinitePermission: boolean;
    }) => {
      const response = await client.put("/grant-invitations/" + shareKey, {
        isInfinitePermission,
        numberOfPermissions,
      });
      return response.data as Invitation;
    },
    onSuccess: (result: Invitation) => {
      setInvite(result);
    },
  });

  const sendInvitationEmail = useMutation({
    mutationFn: async ({
      shareKey,
      emails,
    }: {
      shareKey: string;
      emails: string[];
    }) => {
      await client.post("/grant-invitations/" + shareKey + "/send-email", {
        emails,
      });
    },
    onSuccess: () => {
      toast.info("Send Invitation Emails");
    },
  });

  const trackShareEvent = useMutation({
    mutationFn: async () => {
      await client.post("/grant-invitations-history/event/share", {
        numberOfPermissions: invite?.numberOfPermissions,
        isInfinitePermission: invite?.isInfinitePermission,
        invitationId: invite?.id,
      });
    },
  });

  useEffect(() => {
    // load initial call
    inviteCreate.mutateAsync();
  }, []);
  if (!invite) {
    return null;
  }
  return (
    <div className={styles.modal}>
      <img src={icons} className={styles.icons} />
      <Typo className={styles.tabTitle} semi>
        Share Token(s) with people from your organization
      </Typo>
      <div className={styles.tokenShareTitle}>Number of shared tokens</div>
      <div className={styles.tokenShare}>
        <IncrementNumberInput
          disabled={invite.isInfinitePermission}
          value={invite.numberOfPermissions}
          onValueChanged={(v) =>
            updateInvitation.mutateAsync({
              shareKey: invite.shareKey,
              isInfinitePermission: invite.isInfinitePermission,
              numberOfPermissions: v,
            })
          }
        />
        <img src={budgetMentorIcon} className={styles.budgetMentorIcon} />
        <div>tokens(s)</div>
      </div>
      <div className={styles.infiniteToken}>
        <label>
          <input
            type="checkbox"
            checked={invite.isInfinitePermission}
            onChange={(v) =>
              updateInvitation.mutateAsync({
                shareKey: invite.shareKey,
                isInfinitePermission: !invite.isInfinitePermission,
                numberOfPermissions: invite.numberOfPermissions,
              })
            }
          />{" "}
          Grant infinite permissions to create grants
        </label>
      </div>
      <div className={styles.horizontalLine}></div>

      <div className={styles.tokenShareTitle}>Share via email address</div>
      <div className={styles.tokenShare}>
        <EmailInput
          onEmailSend={(emails) =>
            sendInvitationEmail.mutateAsync({
              shareKey: invite.shareKey,
              emails,
            })
          }
        />
      </div>
      <div style={{ marginTop: 4, color: "#475467", fontSize: 14 }}>
        You can send tokens to multiple recipients. Each email will receive
        instructions on how to claim their token(s).
      </div>
      <div className={styles.horizontalLine}>
        <div className={styles.horizontalLineOr}>or</div>
      </div>

      <div className={styles.tokenShareTitle}>Share via invitation link</div>
      <div className={styles.tokenShare}>
        <InvitationInput
          onInvitationCopied={() => trackShareEvent.mutateAsync()}
          token={invite.shareKey}
        />
      </div>
      <div style={{ marginTop: 4, color: "#475467", fontSize: 14 }}>
        Copy and share this link with one person. The link can be used only
        once.
      </div>
    </div>
  );
}

function InvitationInput({
  token,
  onInvitationCopied,
}: {
  token: string;
  onInvitationCopied: () => void;
}) {
  const url =
    window.location.protocol + "//" + window.location.host + "/invite/" + token;
  return (
    <div className={styles.sendEmail}>
      <input
        className={cx(formStyles.input, styles.inputField)}
        style={{ width: "auto" }}
        defaultValue={url}
        disabled={true}
      />
      <button
        className={styles.sendButton}
        onClick={() => {
          navigator.clipboard.writeText(url);
          toast.info("Copied Link");
          onInvitationCopied();
        }}
      >
        <img src={copyIcon} style={{ height: 20 }} />
        Copy Link
      </button>
    </div>
  );
}

function EmailInput({
  onEmailSend,
}: {
  onEmailSend: (email: string[]) => void;
}) {
  const [emailList, setEmailList] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  function onKeyUp(event: KeyboardEvent) {
    if (["Enter", "Tab", ","].includes(event.key) && inputRef.current) {
      if (validateEmail(inputRef.current.value)) {
        setEmailList([...emailList, inputRef.current.value]);
        inputRef.current.value = "";
      }
    }
  }

  function onPaste(e: ClipboardEvent<HTMLInputElement>) {
    const text = e.clipboardData?.getData("Text");
    const emails = extractEmails(text);
    if (emails.length === 0) {
      return;
    }
    e.preventDefault();
    setEmailList([...emailList, ...emails]);
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: 10, maxHeight: 80, overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {emailList.map((email, index) => (
            <div className={styles.emailBadge}>
              <span>{email}</span>
              <div
                className={styles.emailBadgeClose}
                onClick={() => {
                  setEmailList(emailList.filter((_, i) => i !== index));
                }}
              >
                <XIcon style={{ height: "16px", width: "16px" }} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.sendEmail}>
        <input
          ref={inputRef}
          onKeyUp={onKeyUp}
          onPaste={onPaste}
          placeholder="Enter email ..."
          className={cx(formStyles.input, styles.inputField)}
          style={{ width: "auto" }}
        />
        <button
          className={styles.sendButton}
          onClick={() => {
            const emails = emailList;
            if (inputRef.current && validateEmail(inputRef.current.value)) {
              emails.push(inputRef.current.value);
            }
            if (emails.length === 0) {
              toast.warning("No Email entered!");
            } else {
              onEmailSend(emails);
            }
          }}
        >
          <img src={shareIcon} style={{ height: 20 }} />
          Send
        </button>
      </div>
    </div>
  );
}

function IncrementNumberInput({
  value,
  onValueChanged,
  disabled,
}: {
  value: number;
  onValueChanged: (v: number) => void;
  disabled: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  function handleChangeValue(newValue: number) {
    if (inputRef.current) {
      inputRef.current.value = newValue.toString();
    }
    onValueChanged(newValue);
  }
  return (
    <div className={styles.IncrementNumberInput}>
      <input
        ref={inputRef}
        disabled={disabled}
        defaultValue={value}
        className={cx(formStyles.input, styles.inputField)}
        onBlur={(e) => handleChangeValue(+e.target.value)}
      />
      <button
        disabled={disabled}
        className={styles.buttonMinus}
        onClick={() => handleChangeValue(value - 1)}
      >
        -
      </button>
      <button
        disabled={disabled}
        className={styles.buttonPlus}
        onClick={() => handleChangeValue(value + 1)}
      >
        +
      </button>
    </div>
  );
}

function validateEmail(word: string) {
  return word.includes("@") && word.includes(".");
}

function extractEmails(text: string) {
  const emails = [];
  const words = text.split(/\s+/).map((w) => w.replaceAll(",", ""));

  for (let word of words) {
    if (word.includes("@") && word.includes(".")) {
      const parts = word.split("@");
      if (parts.length === 2 && parts[1].includes(".")) {
        emails.push(word);
      }
    }
  }

  return emails;
}
