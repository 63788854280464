import Modal from "components/modal";
import styles from "../../containers/collapseTable/collapseTable.module.css";
import { Button, Form, Textarea } from "components/form";
import Typo from "components/typo";
import { CommentedItem } from "containers/collapseTable/indirect";
import { ChangeEvent } from "react";

export function IndirectCommentModal({
  show,
  onModalClosed,
  commentedItem,
  handleChangeComment,
  handleSaveCommentModal,
}: {
  show: boolean;
  onModalClosed: () => void;
  commentedItem: CommentedItem | undefined;
  handleChangeComment: (comment: string) => void;
  handleSaveCommentModal: () => void;
}) {
  return (
    <Modal visible={show} title="Leave your comment" onCancel={onModalClosed}>
      <Form onSubmit={handleSaveCommentModal}>
        <Typo type="p" className={styles.modalSubtitle}>
          Leave your comment below {commentedItem?.name || "N/A"} (overridden)
        </Typo>
        <Textarea
          label="Comment"
          placeholder="Enter a comment..."
          name="comment"
          value={commentedItem?.dataItem?.commentIDC}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeComment(event.target.value)
          }
        />
        <div className={styles.modalFooter}>
          <Button
            name="close-modal"
            size="lg"
            type="bordered"
            htmlType="button"
            onClick={onModalClosed}
          >
            Cancel
          </Button>
          <Button name="close-modal" size="lg" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
