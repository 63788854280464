import Tip from "components/tip";
import useUrl from "hooks/useUrl";
import { FC } from "react";
import { FullGrant, SidebarTotals } from "store/grants/types";
import Anchor from "../../anchor";
import styles from "../facilities/facilities.module.css";

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
};

const Setting: FC<Props> = ({ side, totals }) => {
  const url = useUrl();

  const isActive = url.category === "settings" && url.grantId === side.id;

  return (
    <>
      <Anchor
        thin
        className={styles.link}
        to={`/grants/${side.id}/settings/grant`}
        active={isActive && url.subCategory === "grant"}
      >
        General Settings
      </Anchor>
      <Anchor
        thin
        className={styles.link}
        to={`/grants/${side.id}/settings/personnel`}
        active={isActive && url.subCategory === "personnel"}
      >
        Personnel Settings
      </Anchor>
    </>
  );
  return null;
};

export default Setting;
