import { FC, ChangeEvent, useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Errors } from "helpers/errors";
import { trackUserAction } from "helpers/trackUserActions";
import { Button, Checkbox, Form, Input } from "components/form";
import Typo from "components/typo";
import { FormData, Field as FieldType } from "./index";
import styles from "./signUp.module.css";
import PasswordCheck from "../components/passwordCheck";
import Social from "../components/social";
import checkPassword from "helpers/password";

type Props = {
  data: FormData;
  onChange: (field: FieldType) => void;
  onSubmit: () => void;
  handleChangeErrors: (errors: Errors) => void;
  errors: Errors;
  loading?: boolean;
  msLoading: boolean;
  googleLoading: boolean;
  msLogin: () => void;
};

const SignUpStepOne: FC<Props> = (props) => {
  const {
    data,
    msLogin,
    msLoading,
    googleLoading,
    loading,
    handleChangeErrors,
    errors,
    onSubmit,
    onChange,
  } = props;

  const handleBlurInput = useCallback(
    (event?: ChangeEvent<HTMLInputElement>) => {
      trackUserAction("Sign-Up Started");
    },
    []
  );

  const [focus, onChangeFocus] = useState<boolean>(false);
  const isSmallDevice = window.innerWidth < 1025;

  const handleSubmit = useCallback(() => {
    if (!checkPassword(data.password).all) {
      handleChangeErrors({
        password: ["The password is not strong enough"],
      });
      return;
    }

    onSubmit();
  }, [onSubmit, handleChangeErrors, data]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/b9813f56-9709-4821-acc2-30073f0a0df2/platform.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.content}>
      <div>
        <Typo type="h3" className={styles.title}>
          Sign Up
        </Typo>
        <div className={styles.stepTwo}>
          <Social
            msLogin={msLogin}
            text="Continue"
            msLoading={msLoading}
            googleLoading={googleLoading}
          />
          <div className={styles.divider}>OR</div>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className={styles.stepTwo}>
            <Input
              name="email"
              errors={errors.email}
              fullError
              className={styles.input}
              value={data.email}
              label="Email"
              placeholder="Enter your email"
              required
              onBlur={handleBlurInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChange({ email: event.target.value })
              }
            />
            <Input
              name="password"
              required
              onFocus={() => onChangeFocus(true)}
              onBlur={() => onChangeFocus(false)}
              errors={errors.password}
              showHelp={focus}
              type="password"
              className={styles.input}
              value={data.password}
              label="Password"
              help={
                isSmallDevice ? undefined : (
                  <PasswordCheck password={data.password} />
                )
              }
              placeholder="Enter your password"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChange({ password: event.target.value })
              }
            />
            <div className={styles.mobilePasswordCheck}>
              <PasswordCheck mobile password={data.password} />
            </div>
            <Checkbox
              value={data.acceptTerms}
              name="acceptTerms"
              onChange={() =>
                onChange({
                  acceptTerms: !data.acceptTerms,
                })
              }
              required
              label="Do you agree to the terms and conditions?"
            />
            <Checkbox
              value={data.acceptPromotionalContact}
              onChange={() =>
                onChange({
                  acceptPromotionalContact: !data.acceptPromotionalContact,
                })
              }
              name="acceptPromotionalContact"
              label="I consent to be contacted via email and SMS for promotional contact."
            />
          </div>
          <div className={styles.footer}>
            <Button
              size="xl"
              className={styles.btn}
              loading={loading}
              name="submit"
              htmlType="submit"
            >
              Continue
            </Button>
            <Typo>
              <>
                Already have an account?{" "}
                <Link to={`/login?email=${data.email || ""}`}>Sign in</Link>
              </>
            </Typo>
          </div>
        </Form>
      </div>

      <div className={styles.senjaContainer}>
        <div
          className="senja-embed"
          data-id="b9813f56-9709-4821-acc2-30073f0a0df2"
          data-mode="shadow"
          data-lazyload="false"
          style={{ display: "block" }}
        ></div>
      </div>
    </div>
  );
};

export default SignUpStepOne;
