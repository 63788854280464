import { FC, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import CreateBtn from "pages/grant/paper/card/create";
import Typo from "components/typo";
import useUrl from "hooks/useUrl";
import { Equip, FullGrant, SidebarTotals } from "store/grants/types";
import Item from "pages/grant/paper/card/item";
import { TotalAnchor } from "pages/grant/paper/card/anchor";
import useEquipment from "hooks/useEquipment";
import styles from "./equipment.module.css";

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
};
const Equipment: FC<Props> = ({ side, totals }) => {
  const url = useUrl();
  const { destroy, create } = useEquipment();

  const onDelete = useCallback(
    (id: string) => {
      destroy(side.id, id, url.subCategory === id);
    },
    [side, url, destroy]
  );

  const list = useMemo(() => {
    return side.equipment.map((unit: Equip) => {
      const label = unit.name || "Untitled unit";

      const links = [
        {
          label: "Equipment info",
          to: `/grants/${side.id}/equipment/${unit.id}/edit`,
          active:
            url.grantId === side.id &&
            url.subCategory === unit.id &&
            url.categoryId === "edit",
        },
        ...side.years.map((year: string[], idx: number) => ({
          to: `/grants/${side.id}/equipment/${unit.id}/compensation/${idx}`,
          label: `${dayjs(year[0]).format("M/D/YYYY")} - ${dayjs(
            year[1]
          ).format("M/D/YYYY")}`,
          active:
            url.grantId === side.id &&
            url.subCategory === unit.id &&
            url.section === `${idx}`,
          isDisabled: !unit.name,
        })),
      ];

      return (
        <Item
          key={unit.id}
          onDelete={() => onDelete(unit.id)}
          title={label}
          links={links}
          active={url.subCategory === unit.id}
        />
      );
    });
  }, [side, onDelete, url]);

  return (
    <>
      {list}

      <div>
        {side.equipment.length < 1 ? (
          <Typo className={styles.placeholder} size={14} type="div">
            No units have been created yet
          </Typo>
        ) : (
          <TotalAnchor
            total={totals.equipmentTotalAmount}
            to={`/grants/${side.id}/equipment/totals`}
            active={
              url.category === "equipment" && url.subCategory === "totals"
            }
          >
            Totals
          </TotalAnchor>
        )}
        <CreateBtn
          tip="To add equipment, you must add grant general information"
          name="add-grant-equipment"
          side={side}
          onClick={() => create(side.id)}
        >
          Add Equipment
        </CreateBtn>
      </div>
    </>
  );
};

export default Equipment;
