import { FC, useCallback, ChangeEvent, useMemo } from "react";
import cx from "classnames";
import { ceil } from "lodash";
import {
  Input,
  Form,
  Button,
  DatePicker,
  Select,
  Toggler,
} from "components/form";
import Typo from "components/typo";
import styles from "./personForm.module.css";
import { Errors } from "helpers/errors";
import useEnums from "hooks/useEnums";
import * as T from "hooks/requestTypes";
import useGrants from "hooks/useGrants";
import { ListItem } from "helpers/utils";
import { percentToFixed } from "helpers/numbers";
import { FieldErrors } from "react-hook-form";

type Field = Record<string, any>;

type Props = {
  data: T.Person;
  errors: Errors;
  nsfCompensation: T.PersonalNSFCompensationField[];
  onChangeNSFCompensation: (data: T.PersonalNSFCompensationField[]) => void;
  previewFields: T.PersonnelPreviewFields;
  onChange: (field: Field) => void;
  onChangeDates: (field: Field) => void;
  dates: {
    startDate: any;
    endDate: any;
  };
  onBlurDates: (field: "startDate" | "endDate", value: any) => void;
  onBlurYearSalary: () => void;
  onSubmit: () => void;
  isUpdate?: boolean;
  isSenior?: boolean;
  disabled?: boolean;
  loading?: boolean;
  trackError?: (errors: FieldErrors) => void;
  trackExit?: () => void;
};

const compensationHelp =
  'If "By months" is selected, you will be prompted to answer previous months of compensation already being received to help you figure out the maximum number of months of salary you are able to request due to the government limiting all compensation from all grants to a total of 2 months of annual salary. \n' +
  "If you currently have 2 months of summer support already being funded or there is another contingency (e.g. you are being supported over 9 months currently at your university), your institution might suggest calculating summer support based on a percentage of annual salary. This option is provided to allow users flexibility. \n" +
  "If you are going to request academic months as well as summer months, or you are requesting only academic months, or if you are going to request a course buyout, then choose the percent of salary option.";

const PersonForm: FC<Props> = ({
  data,
  isSenior,
  disabled,
  dates,
  onChangeDates,
  onBlurDates,
  onChangeNSFCompensation,
  isUpdate,
  nsfCompensation,
  previewFields,
  onBlurYearSalary,
  loading,
  onSubmit,
  errors,
  onChange,
  trackError,
  trackExit,
}) => {
  const { personRoles, jobCategory, compensationType } = useEnums();
  const { grant } = useGrants();

  const handleChangeNSFCompensation = useCallback(
    (kind: any, idx: number) => {
      const newNSFCompensation = nsfCompensation.map(
        (item: T.PersonalNSFCompensationField, index: number) => {
          if (index === idx) return { ...item, kind };
          return item;
        }
      );
      onChangeNSFCompensation(newNSFCompensation);
    },
    [nsfCompensation, onChangeNSFCompensation]
  );

  const handleSubmit = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      onSubmit();
    },
    [onSubmit]
  );

  const disabledFields = data.isAccountOwner;

  const personRolesOptions = useMemo(() => {
    return personRoles.map((item: ListItem) => {
      const roleCount = grant.rolesCount[item.value as "coPi" | "pi"];
      const label =
        roleCount !== undefined ? `${item.label}(${roleCount})` : item.label;
      return {
        ...item,
        label,
        disabled: roleCount !== undefined && roleCount < 1,
      };
    });
  }, [personRoles, grant]);

  return (
    <Form
      prompt
      className={styles.wrapper}
      onSubmit={handleSubmit}
      trackError={trackError}
      trackExit={trackExit}
    >
      <div className={styles.field}>
        <Input
          name="firstName"
          disabled={disabledFields}
          value={data.firstName}
          className={styles.input}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ firstName: event.target.value })
          }
          placeholder="Enter first name"
          label="First name"
          required
          errors={errors.firstName}
          auto
        />
        <Input
          name="lastName"
          disabled={disabledFields}
          value={data.lastName}
          className={styles.input}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ lastName: event.target.value })
          }
          placeholder="Enter last name"
          label="Last name"
          required
          errors={errors.lastName}
          auto
        />
      </div>
      <div className={styles.field}>
        <Input
          name="positionTitle"
          value={data.positionTitle}
          className={styles.fullInput}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ positionTitle: event.target.value })
          }
          placeholder="Enter position title"
          label="Position title"
          errors={errors.positionTitle}
          auto
        />
      </div>
      <div className={styles.field}>
        <Input
          name="subGroup"
          value={data.subGroup}
          className={styles.fullInput}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ subGroup: event.target.value })
          }
          placeholder="Enter college"
          label="College (if a subgroup of your university)"
          auto
          errors={errors.subGroup}
        />
      </div>
      <div className={styles.field}>
        <Input
          name="department"
          value={data.department}
          className={styles.fullInput}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ department: event.target.value })
          }
          placeholder="Enter department"
          label="Department"
          errors={errors.department}
          auto
        />
      </div>
      <div
        className={cx(
          styles.field,
          cx({
            [styles.twoInputsFieldWithHelp]: !isSenior,
            [styles.twoInputsField]: isSenior,
          })
        )}
      >
        {isSenior ? (
          <Select
            name="role"
            value={data.role}
            options={personRolesOptions}
            className={styles.input}
            onChange={(role: any) => onChange({ role })}
            placeholder="Select a role in the grant"
            label="Role in the Grant"
            required
            errors={errors.role}
          />
        ) : (
          <Select
            name="jobCategory"
            value={data.jobCategory}
            options={jobCategory.academicResearchAssociate}
            className={styles.input}
            onChange={(jobCategory: any) => onChange({ jobCategory })}
            help="Your institutional budget likely details specific roles (e.g., Academic Research Associate) for precise internal management and accountability. The NSF requires these specific roles to be grouped into broader categories. An Academic Research Associate is normally categorized under Senior Personnel. For detailed information use the built-in guide (“Show a guide” button)"
            placeholder="Select a job category"
            label="Job category (according to NSF)"
            required
            errors={errors.jobCategory}
          />
        )}
        <Input
          name="yearSalary"
          money={true}
          value={data.salary.amount}
          type="number"
          className={styles.input}
          onBlur={onBlurYearSalary}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({
              salary: {
                ...data.salary,
                amount: event.target.value,
              },
            })
          }
          placeholder="Enter year salary"
          label="Year salary"
          required
          errors={errors["salary.amount"]}
          auto
        />
      </div>
      <div className={styles.field}>
        <DatePicker
          name="startDate"
          value={dates.startDate}
          className={styles.input}
          onBlur={() => onBlurDates("startDate", data.salary.startDate)}
          onChange={(startDate: any) => onChangeDates({ startDate })}
          placeholder="Select a start date"
          label="Start Date of Salary"
          required
          errors={errors["salary.startDate"]}
        />
        <DatePicker
          name="endDate"
          value={dates.endDate}
          className={styles.input}
          onBlur={() => onBlurDates("endDate", data.salary.endDate)}
          onChange={(endDate: any) => onChangeDates({ endDate })}
          minDate={dates.startDate || undefined}
          placeholder="Select an end date"
          label="End Date of Salary"
          required
          errors={errors["salary.endDate"]}
        />
      </div>
      <div
        className={cx(
          styles.field,
          styles.twoInputsField,
          styles.withoutBorder
        )}
      >
        <Input
          name="monthCompensatedByOrganization"
          value={
            data.monthCompensatedByOrganization
              ? ceil(Number(data.monthCompensatedByOrganization), 2)
              : data.monthCompensatedByOrganization
          }
          className={cx(styles.input, styles.shortLabel)}
          onBlur={onBlurYearSalary}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ monthCompensatedByOrganization: event.target.value })
          }
          placeholder="Number of months compensated by university"
          label="Number of months compensated by university per year"
        />
        <Input
          name="salaryPaidPerMonthByOrganization"
          money
          value={
            previewFields.salaryPaidPerMonthByOrganization
              ? ceil(Number(previewFields.salaryPaidPerMonthByOrganization), 2)
              : previewFields.salaryPaidPerMonthByOrganization
          }
          disabled
          className={cx(styles.input, styles.shortLabel)}
          placeholder="Amount paid per month"
          label="Amount paid per month by the university"
        />
      </div>
      <div className={styles.field}>
        <Input
          name="percentOfSalary"
          value={
            previewFields.percentOfSalary
              ? percentToFixed(previewFields.percentOfSalary, 3)
              : previewFields.percentOfSalary
          }
          disabled
          className={styles.fullInput}
          placeholder="Percent of salary paid per month"
          label="Percent of salary paid per month"
        />
      </div>
      <div className={styles.field}>
        <Input
          name="fringeBenefitRate"
          type="number"
          value={data.fringeBenefitRate}
          className={styles.input}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ fringeBenefitRate: event.target.value })
          }
          placeholder="Enter fringe benefit rate"
          label="Person's Fringe Benefit Rate (%)"
          required
          errors={errors.fringeBenefitRate}
        />
      </div>
      <div
        className={cx(styles.field, {
          [styles.withoutBorder]: !data.isRequestingSupport,
        })}
      >
        <Toggler
          className={styles.input}
          name="isRequestingSupport"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ isRequestingSupport: event.target.checked })
          }
          label="Is this person requesting support?"
          invert
          checked={data.isRequestingSupport}
        />
      </div>
      <div className={styles.compensations}>
        {data.isRequestingSupport
          ? nsfCompensation.map(
              (item: T.PersonalNSFCompensationField, idx: number) => (
                <div key={idx} className={styles.compensation}>
                  <Typo type="h6" semi>{`Year ${idx + 1}`}</Typo>
                  <div className={styles.selectWrapper}>
                    <Select
                      name={`nsfCompensation_${idx}_kind`}
                      className={styles.select}
                      options={compensationType}
                      placeholder="Select a method for entering compensation"
                      label="How would you like to enter yearly NSF compensation?"
                      help={idx < 1 ? compensationHelp : undefined}
                      helpInLabel
                      required={data.isRequestingSupport}
                      value={nsfCompensation[idx]?.kind}
                      disabled={!data.isRequestingSupport}
                      errors={errors[`nsfCompensation[${idx}].kind`]}
                      onChange={(kind: T.PersonalNSFCompensationField) =>
                        handleChangeNSFCompensation(kind, idx)
                      }
                    />
                  </div>
                </div>
              )
            )
          : null}
      </div>
      <div className={styles.footer}>
        <Button
          htmlType="submit"
          disabled={disabled}
          loading={loading}
          name="submit-person"
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default PersonForm;
