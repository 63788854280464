import { FC, useCallback, useMemo } from "react";
// hooks
import useUrl from "hooks/useUrl";
import usePublications from "hooks/usePublications";
// types
import {
  PublicationsUnit,
  PublicationsUnitYear,
} from "store/grants/otherDirectCosts/Publications/types";
// components
import Typo from "components/typo";
import CreateBtn from "pages/grant/paper/card/create";
// styles
import styles from "pages/grant/paper/card/folders/otherDirectCosts/otherDirectCosts.module.css";
import dayjs from "dayjs";
import Item from "pages/grant/paper/card/item";
import Anchor, { TotalAnchor } from "pages/grant/paper/card/anchor";
import { FullGrant } from "store/grants/types";

type Props = {
  side: FullGrant;
};

const Publications: FC<Props> = ({ side }) => {
  const url = useUrl();
  const { isLoading, createUnit, deleteUnit } = usePublications();
  const isGeneralInfoFolderActive =
    url.subCategory === "publications" && url.categoryId === "general-info";
  const isTotalFolderActive =
    url.subCategory === "publications" && url.categoryId === "totals";

  const handleDeleteUnit = useCallback(
    (id?: string) => {
      if (id) deleteUnit(side.id, id, url.categoryId === id);
    },
    [deleteUnit, url, side]
  );

  const list = useMemo(() => {
    return side.otherDirectCosts.publications.units.map(
      (item: PublicationsUnit) => {
        const links = [
          {
            label: "Unit info",
            to: `/grants/${side.id}/otherDC/publications/${item.id}/edit`,
            active: url.categoryId === item.id && url.section === "edit",
          },
          ...item.years.map((yearItem: PublicationsUnitYear, idx: number) => ({
            label: `${dayjs(yearItem.year[0]).format("M/D/YYYY")} - ${dayjs(
              yearItem.year[1]
            ).format("M/D/YYYY")}`,
            to: `/grants/${side.id}/otherDC/publications/${item.id}/year/${idx}`,
            active:
              url.categoryId === item.id &&
              url.section === "year" &&
              url.sectionId === `${idx}`,
            isDisabled: !item.name,
          })),
        ];
        return (
          <Item
            title={item.name || "Untitled unit"}
            onDelete={() => handleDeleteUnit(item.id)}
            links={links}
            key={item.id}
            active={url.categoryId === item.id}
          />
        );
      }
    );
  }, [side, handleDeleteUnit, url]);
  return (
    <>
      <Anchor
        thin
        active={isGeneralInfoFolderActive}
        to={`/grants/${side.id}/otherDC/publications/general-info`}
      >
        General info
      </Anchor>

      {list.length > 0 ? (
        list
      ) : (
        <Typo className={styles.placeholder} size={14} type="div">
          No units have been created yet
        </Typo>
      )}

      {list.length > 0 ? (
        <TotalAnchor
          total={side.otherDirectCosts.publicationsTotalAmount}
          active={isTotalFolderActive}
          to={`/grants/${side.id}/otherDC/publications/totals`}
        >
          Totals
        </TotalAnchor>
      ) : null}

      <CreateBtn
        loading={isLoading}
        onClick={() => createUnit(side.id)}
        name="add-grant-publication"
        side={side}
        tip="To add publication, you must save general info first"
      >
        Add publication costs
      </CreateBtn>
    </>
  );
};

export default Publications;
