import { FC, useEffect } from "react";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import CreateGrant, { CreateGrantForm } from "pages/grant/one/create";
import useGrants from "hooks/useGrants";
import useUI from "hooks/useUI";
import EditGrant from "pages/grant/one/edit";
import Equipment from "pages/grant/one/equipment";
import Participants from "pages/grant/one/participant";
import Travel from "pages/grant/one/travel";
import OtherDirectCosts from "pages/grant/one/otherDirectCosts";
import Fees from "pages/grant/one/fees";
import MTDC from "pages/grant/one/mtdc";
import CostSharing from "./costSharing";
import Spinner from "components/spinner";
import styles from "./one.module.css";
import Facilities from "./facilities";
import Indirect from "./indirect";
import FinalPage from "./final";
import PaidRoute from "containers/paidRoute";
import { trackUserPageview } from "helpers/trackUserActions";
import { useMixPanel } from "../../../hooks/useMixPanel";
import Personnel from "pages/grant/one/personnel";

const GrantIndex: FC = () => {
  const { pageViewed } = useMixPanel();
  const params: Record<"id", string> = useParams();
  const { getOneWithSideGlobal, loading, grant } = useGrants();
  const {
    sidebarWidth,
    changeMTDCGrantId,
    indirectCostGrantId,
    changeICGrantId,
    mtdcGrantId,
    changeFinalGrantId,
    finalGrantId,
  } = useUI();

  useEffect(() => {
    if (
      (mtdcGrantId && mtdcGrantId !== params.id) ||
      (finalGrantId && finalGrantId !== params.id) ||
      (indirectCostGrantId && indirectCostGrantId !== params.id)
    ) {
      changeMTDCGrantId("");
      changeICGrantId("");
      changeFinalGrantId("");
    }
  }, [mtdcGrantId, finalGrantId, indirectCostGrantId, params.id]);

  useEffect(() => {
    if (params.id !== grant.id) {
      getOneWithSideGlobal(params.id);
    }
  }, [params.id, grant.id]);

  useEffect(() => {
    trackUserPageview("One Grant Page");
    //track mix panel Page Viewed event
    pageViewed("One Grant Page");
  }, []);

  return (
    <>
      <Switch>
        <Route path="/grants/:id/create" exact component={CreateGrant} />
        <Route
          path="/grants/:id/edit"
          exact
          render={(props) => (
            <Redirect to={`/grants/${props.match.params.id}/settings/grant`} />
          )}
        />
        <Route
          path="/grants/:id/settings/grant"
          exact
          render={() => <EditGrant />}
        />
        <Route
          path="/grants/:id/settings/personnel"
          exact
          render={() => (
            <div style={{ padding: "2em" }}>
              <CreateGrantForm />
            </div>
          )}
        />
        <Route path="/grants/:id/personnel" component={Personnel} />
        <Route
          path="/grants/:id/equipment"
          render={() => (
            <PaidRoute>
              <Equipment />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/travel"
          render={() => (
            <PaidRoute>
              <Travel />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/events"
          render={() => (
            <PaidRoute>
              <Participants />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/otherDC"
          render={() => (
            <PaidRoute>
              <OtherDirectCosts />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/fees"
          render={() => (
            <PaidRoute>
              <Fees />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/costSharing"
          render={() => (
            <PaidRoute>
              <CostSharing />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/facilities/rates"
          render={() => (
            <PaidRoute>
              <Facilities />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/mtdc"
          render={() => (
            <PaidRoute>
              <MTDC />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/indirect"
          render={() => (
            <PaidRoute>
              <Indirect />
            </PaidRoute>
          )}
        />
        <Route
          path="/grants/:id/final"
          render={() => (
            <PaidRoute>
              <FinalPage />
            </PaidRoute>
          )}
        />
      </Switch>
      {loading ? (
        <Spinner
          style={{
            width: `calc(100vw - ${sidebarWidth}px)`,
            left: sidebarWidth,
          }}
          full
          className={styles.spinner}
        />
      ) : null}
    </>
  );
};

export default GrantIndex;
